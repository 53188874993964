import './DataLakehouseDashboard.scss';
import './DataLakehouseDashboardSupplier.scss';
import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import { getMfrStateFromSearchStr, updateUrlState } from 'helpers/UrlUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import * as DataLakehouseDashboardApiCalls from './private/DataLakehouseDashboardApiCalls';
import { SectionAssessments } from './private/SectionAssessments';
import { SectionDataJobs } from './private/SectionDataJobs';
import { SectionImageQuality } from './private/SectionImageQuality';
import { SectionImgBotEngine } from './private/SectionImgBotEngine';
import { SectionSummaryCompany } from './private/SectionSummaryCompany';

/**
 * Renders Data Lakehouse dashboard for Supplier role
 *
 * @return render
 */
const DataLakehouseDashboardSupplier = () => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const location = useLocation();

  const { activeUser } = RootHooks.useActiveUser();

  const mfrData = activeUser?.profile?.manufacturer ?? null;
  const hasChildren = mfrData?.has_children;

  // Holds data for determening parent/child values
  const mfrId = activeUser?.profile?.manufacturer?.id ?? null;
  const brandId = getMfrStateFromSearchStr(location.search)?.child_company_id;
  const companyId = brandId || mfrId;

  // Holds data jobs data
  const [dataJobsData, setDataJobsData] = useState(null);
  const [dataJobsDataStatus, setDataJobsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds image quality assessment data
  const [imageQualityAssessmentData, setImageQualityAssessmentData] = useState([]);
  const [imageQualityAssessmentDataStatus, setImageQualityAssessmentDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Refresh dashboard data on mfr id set
  useEffect(() => {
    DataLakehouseDashboardApiCalls.doGetDataJobs({
      mfrId: companyId,
      isMounted,
      setDataJobsData,
      setDataJobsDataStatus,
    });

    DataLakehouseDashboardApiCalls.doGetImageQualityAssessment({
      id: companyId,
      type: 'manufacturer',
      isMounted,
      setImageQualityAssessmentData,
      setImageQualityAssessmentDataStatus,
    });
  }, [isMounted, companyId]);

  const isLoading = [dataJobsDataStatus, imageQualityAssessmentDataStatus].includes(
    ActionStatusConstants.ISBUSY
  );

  return (
    <div className="data-lakehouse-dashboard data-lakehouse-dashboard-supplier">
      {companyId && (
        <SectionSummaryCompany
          selectedManufacturer={mfrId}
          onSelectManufacturer={(v) => updateUrlState(history, location, v, null)}
          selectedBrand={brandId}
          onSelectBrand={(v) => updateUrlState(history, location, mfrId, v)}
          companyId={companyId}
          hasChildren={hasChildren}
        />
      )}

      {/* details section */}
      {isLoading ? (
        <div className="details-loading-wrapper">
          <LoadingSpinner fast style={{ fontSize: '3em', marginTop: '3em' }} />
        </div>
      ) : (
        <>
          <SectionImgBotEngine />

          {imageQualityAssessmentData?.length > 0 ||
          (imageQualityAssessmentData.length === 0 &&
            [ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
              imageQualityAssessmentDataStatus
            )) ? (
            <SectionImageQuality
              type="manufacturer"
              id={companyId}
              imageQualityAssessmentData={imageQualityAssessmentData}
              imageQualityAssessmentDataStatus={imageQualityAssessmentDataStatus}
            />
          ) : null}

          <SectionDataJobs
            dataJobsData={dataJobsData}
            dataJobsDataStatus={dataJobsDataStatus}
            showCreateBtn
            title="Most Recent Data Jobs"
          />

          {/* TODO: Delete this when releasing IDS above */}
          {/* <SectionAssessments mfrId={companyId} title="Most Data File Recent Assessments" /> */}
        </>
      )}
    </div>
  );
};

export { DataLakehouseDashboardSupplier };
