import React, { useEffect, useState } from 'react';

import * as ApiCalls from 'api/ApiCalls';
import * as Utils from 'helpers/Utils';

import './ImgBotEngineStats.scss';
import { CircularProgressBar } from '../common/CircularProgressBar/CircularProgressBar';
import { ValueBlock } from '../common/ValueBlock/ValueBlock';
import { IdsSelfServiceQuotaBanner } from './_private/IdsSelfServiceQuotaBanner';
import { IdsShortDescriptionBlock } from './_private/IdsShortDescriptionBlock';
import { useHistory, useLocation } from 'react-router-dom';

const ImgBotEngineStats = () => {
  const history = useHistory();
  const location = useLocation();

  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [emptyStatistics, setEmptyStatistics] = useState(false);

  useEffect(() => {
    setIsStatisticsLoading(true);
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: '/bots/process-job/usage',
      onSuccess: (res) => {
        setStatistics(res.data);
        if (
          [
            res.data?.jobs?.not_started ?? 0,
            res.data?.jobs?.started ?? 0,
            res.data?.jobs?.canceled ?? 0,
          ].every((x) => x === 0)
        ) {
          setEmptyStatistics(true);
        }
      },
      onEnd: () => {
        setIsStatisticsLoading(false);
      },
    });
  }, []);

  const getCurrentMonthName = () => {
    const date = new Date();
    return date.toLocaleString('en', { month: 'long' });
  };

  const getMarketReadinessStatusClass = (marketReadiness) => {
    if (Number.isFinite(marketReadiness)) {
      if (marketReadiness >= 0.8) {
        return CircularProgressBar.STATUSES.GOOD;
      }
      if (marketReadiness >= 0.55) {
        return CircularProgressBar.STATUSES.ACCEPTABLE;
      }
      return CircularProgressBar.STATUSES.POOR;
    }
    return null;
  };

  return (
    <>
      <IdsSelfServiceQuotaBanner />

      <IdsShortDescriptionBlock />

      <div className="ids-summary-wrapper">
        <div className="header">
          <div className="title">{getCurrentMonthName()} Usage Overview</div>
        </div>
        {emptyStatistics ? (
          <div className="empty-statistics">
            <div className="title">You have not run any jobs this month.</div>
            <div className="description">
              <button
                type="button"
                className="btn btn-primary btn-request-data"
                onClick={() => history.push(`/image-databot-suite${location?.search}`)}
              >
                Get Started!
              </button>
            </div>
          </div>
        ) : (
          <div className="ids-summary">
            <div className="value-group">
              <ValueBlock
                title="Jobs"
                value={Utils.renderLoadingOrContent(
                  (statistics?.jobs?.started || 0) +
                    (statistics?.jobs?.canceled || 0) +
                    (statistics?.jobs?.not_started || 0) +
                    (statistics?.jobs?.rejected || 0),
                  isStatisticsLoading,
                  0
                )}
                additionalData={[
                  {
                    label: ``,
                    value: `${statistics?.jobs?.started || 0} active | ${
                      statistics?.jobs?.not_started || 0
                    } pending | ${statistics?.jobs?.canceled || 0} canceled | ${
                      statistics?.jobs?.rejected || 0
                    } rejected`,
                  },
                ]}
                className="ids-summary-jobs span-2r"
                tooltip="Overview of the jobs including pending, active, canceled and rejected."
                // subtitle="Overview"
              />
              <ValueBlock
                title="Assessment"
                value={Utils.renderLoadingOrContent(
                  statistics?.jobs?.assessment_completed,
                  isStatisticsLoading,
                  0
                )}
                tooltip="Number of assessments completed."
                subtitle="Jobs Done"
              />
              <ValueBlock
                title="Enhancement"
                value={Utils.renderLoadingOrContent(
                  statistics?.jobs?.enhancement_completed,
                  isStatisticsLoading,
                  0
                )}
                tooltip="Number of enhancements completed."
                subtitle="Jobs Done"
              />
            </div>

            <div className="value-group">
              <ValueBlock
                title="Pending Extraction"
                value={Utils.renderLoadingOrContent(
                  statistics?.items?.pending_extraction,
                  isStatisticsLoading,
                  0
                )}
                subtitle="Images"
                className="ids-summary-items"
                tooltip="Total number of images to be extracted."
              />
              <ValueBlock
                title="Extracted"
                value={Utils.renderLoadingOrContent(
                  statistics?.items?.extracted?.success + statistics?.items?.extracted?.canceled,
                  isStatisticsLoading,
                  0
                )}
                additionalData={[
                  {
                    label: ``,
                    value: `${statistics?.items?.extracted?.success || 0} completed | ${
                      statistics?.items?.extracted?.canceled || 0
                    } canceled`,
                  },
                ]}
                subtitle="Images"
                className="ids-summary-items"
                tooltip="Total number of images extracted."
              />

              <ValueBlock
                title="Assessed"
                value={Utils.renderLoadingOrContent(
                  statistics?.items?.assessed?.success + statistics?.items?.assessed?.canceled,
                  isStatisticsLoading,
                  0
                )}
                additionalData={[
                  {
                    label: ``,
                    value: `${statistics?.items?.assessed?.success || 0} completed | ${
                      statistics?.items?.assessed?.canceled || 0
                    } canceled`,
                  },
                ]}
                subtitle="Images"
                className="ids-summary-items"
                tooltip="Total number of images assessed."
              />
              <ValueBlock
                title="Enhanced"
                value={Utils.renderLoadingOrContent(
                  statistics?.items?.enhanced,
                  isStatisticsLoading,
                  0
                )}
                subtitle="Images"
                className="ids-summary-items"
                tooltip="Total number of images enhanced."
              />
            </div>

            <section className="circular-progress-bar-wrapper">
              <div className="circular-progress-bar">
                <CircularProgressBar
                  percentage={(statistics?.pass_rate?.initial || 0) * 100}
                  label="Initial Market Ready"
                  status={getMarketReadinessStatusClass(statistics?.pass_rate?.initial || 0)}
                  isSemiCircle
                  isShowPercentage
                  percentageDecimalPlaces={2}
                  isShowStatus
                />
              </div>

              <div className="circular-progress-bar">
                <CircularProgressBar
                  percentage={(statistics?.pass_rate?.final || 0) * 100}
                  label="Final Market Ready"
                  status={getMarketReadinessStatusClass(statistics?.pass_rate?.final || 0)}
                  isSemiCircle
                  isShowPercentage
                  percentageDecimalPlaces={2}
                  isShowStatus
                />
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export { ImgBotEngineStats };
