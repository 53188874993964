import React, { useState, useEffect, useCallback } from 'react';

import { StyledMultiselect } from 'components/common/StyledMultiselect/StyledMultiselect';
import { DraggableTable } from 'components/common/TableDragRows/DraggableTable';
import { getRootMenuPortalTarget } from 'helpers/Utils';

const HeaderSelector = ({
  selectedHeaders,
  setSelectedHeaders,
  headersList,
  disabled,
  canMove = true,
  isInvalid = false,
  errorMessage = undefined,
}) => {
  const menuPortalTarget = getRootMenuPortalTarget();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (headersList?.length) {
      setOptions(
        headersList.map((item) => {
          return { value: item, label: item, name: item };
        })
      );
    } else {
      setOptions([]);
    }
  }, [headersList, setSelectedHeaders]);

  const onSelectedHeadersChanged = useCallback(
    (v) => {
      setSelectedHeaders(v);
    },
    [setSelectedHeaders]
  );

  return (
    <>
      <div className="header-selector">
        <StyledMultiselect
          defaultValue={selectedHeaders}
          values={selectedHeaders}
          options={options}
          setOnChange={onSelectedHeadersChanged}
          getOptionValue={(option) => option.value}
          isMulti
          isClearable
          isSearchable
          closeMenuOnSelect={false}
          canReset
          isDisabled={disabled}
          menuPortalTarget={menuPortalTarget}
          placeholder="Select a column to add and arrange."
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
      </div>
      <div className="table-wrapper">
        <DraggableTable
          headers={[{ header: 'Column Name', key: 'name', id: 1 }]}
          data={selectedHeaders}
          setData={setSelectedHeaders}
          actions={{ move: canMove, delete: false }}
        />
      </div>
    </>
  );
};

export { HeaderSelector };
