import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';

import { BotConstants } from 'constants/BotConstants';

import { IdsConstants } from './IdsConstants';

/**
 * Returns the current phase key of an IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return phase key
 */
const getPhase = (processJob) => {
  let output = IdsConstants.PHASES.ids_preprocessing.key;

  const botStatuses = processJob?.bot_statuses;
  if (botStatuses) {
    if (
      botStatuses[IdsConstants.PHASES.ids_final_review.key]?.status &&
      botStatuses[IdsConstants.PHASES.ids_final_review.key]?.status !==
        BotConstants.BOT_STATUSES.APPROVED
    ) {
      output = IdsConstants.PHASES.ids_final_review.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_quality_check.key]) {
      output = IdsConstants.PHASES.ids_quality_check.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_final_assessment.key]) {
      output = IdsConstants.PHASES.ids_final_assessment.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_enhancement.key]) {
      output = IdsConstants.PHASES.ids_enhancement.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_initial_assessment.key]) {
      output = IdsConstants.PHASES.ids_initial_assessment.key;
    } else if (
      botStatuses[IdsConstants.PHASES.ids_initial_review.key]?.status &&
      botStatuses[IdsConstants.PHASES.ids_initial_review.key]?.status !==
        BotConstants.BOT_STATUSES.APPROVED
    ) {
      output = IdsConstants.PHASES.ids_initial_review.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_extraction.key]) {
      output = IdsConstants.PHASES.ids_extraction.key;
    } else if (botStatuses[IdsConstants.PHASES.ids_preprocessing.key]) {
      output = IdsConstants.PHASES.ids_preprocessing.key;
    }
  }
  return output;
};

/**
 * Returns the current phase label of an IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return phase label
 */
const getPhaseLabel = (processJob) => {
  let output = null;

  const phase = getPhase(processJob);

  if (phase && phase in IdsConstants.PHASES) {
    output = IdsConstants.PHASES[phase].label;
  }

  return output;
};

/**
 * Returns the current status key of an IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return status key
 */
const getStatus = (processJob) => {
  const output = {
    status: BotConstants.BOT_STATUSES.PENDING,
    label: { status: undefined, step: undefined, progress: undefined },
  };

  const phase = getPhase(processJob);

  if (phase) {
    const botStatus = processJob.bot_statuses[phase];
    const status = botStatus?.status;

    if (status && Object.values(BotConstants.BOT_STATUSES).includes(status)) {
      output.status = status;

      const progress = botStatus.status_details?.progress;
      if (status !== BotConstants.BOT_STATUSES.FAILED && progress?.progress !== undefined) {
        const statusLabel = BotConstants.BOT_STATUS_LABELS[status];
        // output.label = `${statusLabel} (${stepStr}${progress.progress.toFixed(0)}%)`;
        output.label = {
          status: statusLabel,
          step: progress.step,
          progress: progress.progress.toFixed(0),
        };
      } else {
        output.label = { status: BotConstants.BOT_STATUS_LABELS[status] };
      }
    } else if (
      processJob.statistics?.image_extractions_percents ||
      processJob.statistics?.image_extraction_failed_message
    ) {
      const extractionPercent = processJob.statistics.image_extractions_percents;

      if (processJob.statistics?.image_extraction_failed_message) {
        output.status = BotConstants.BOT_STATUSES.FAILED;
      } else if (extractionPercent < 100) {
        output.status = BotConstants.BOT_STATUSES.RUNNING;
        output.label = {
          status: BotConstants.BOT_STATUS_LABELS.running,
          progress: extractionPercent.toFixed(0),
        };
      } else {
        output.status = BotConstants.BOT_STATUSES.COMPLETED;
      }
    }
  } else if (!processJob?.set_for_run) {
    output.status = BotConstants.BOT_STATUSES.NOT_RUN;
  }

  return output;
};

/**
 * Returns the current status label of an IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return status label
 */
const getStatusLabel = (processJob) => {
  let output = BotConstants.BOT_STATUS_LABELS.pending;

  const status = getStatus(processJob);

  if (!status.label?.status && BotConstants.BOT_STATUS_LABELS[status.status]) {
    output = { status: BotConstants.BOT_STATUS_LABELS[status.status] };
  } else if (status.label?.status) {
    output = status.label;
  } else {
    output = { status: status.status };
  }

  return output;
};

/**
 * Returns error message for failed IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return error message
 */
const getTooltipInfo = (processJob) => {
  if (!processJob) {
    return null;
  }
  const phase = getPhase(processJob);

  if (Object.keys(processJob.bot_statuses).includes(phase)) {
    const botStatus = processJob.bot_statuses[phase];
    const isFailed = botStatus?.status === BotConstants.BOT_STATUSES.FAILED;
    const isRunning = botStatus?.status === BotConstants.BOT_STATUSES.RUNNING;
    const isPending = botStatus?.status === BotConstants.BOT_STATUSES.PENDING;
    const isCompleted = botStatus?.status === BotConstants.BOT_STATUSES.COMPLETED;
    const isUnderReview = botStatus?.status === BotConstants.BOT_STATUSES.UNDER_REVIEW;

    if (isFailed) {
      const errorType =
        IdsConstants.ERROR_TYPE_MESSAGES[botStatus?.status_details?.error?.error_type];
      const errorLocation =
        IdsConstants.ERROR_LOCATION_MESSAGES[botStatus?.status_details?.error?.location];

      if (errorType && errorLocation) {
        return { msg: `${errorType} (${errorLocation})`, icon: 'question-circle' };
      }
    } else if (isUnderReview) {
      if (phase === IdsConstants.PHASES.ids_initial_review.key) {
        return {
          msg: 'We are currently reviewing the images you have submitted. You will receive an email once the review is complete.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_final_review.key) {
        return {
          msg: 'We are currently reviewing the results. You will receive an email once the review is complete.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_quality_check.key) {
        return {
          msg: 'We are currently reviewing the results. You will receive an email once the review is complete.',
          icon: 'info-circle',
        };
      }
    } else if (isRunning) {
      const secs = botStatus?.status_details?.progress?.estimated;
      if (secs) {
        return { msg: `ETA: ${moment.utc(secs * 1000).format('HH:mm:ss')}`, icon: 'info-circle' };
      }
    } else if (isPending) {
      if (phase === IdsConstants.PHASES.ids_initial_review.key) {
        return {
          msg: 'The images you have submitted are pending to be reviewed.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_final_review.key) {
        return {
          msg: 'The results are pending to be reviewed.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_quality_check.key) {
        return {
          msg: 'The results of this run are pending to be reviewed.',
          icon: 'info-circle',
        };
      }

      return { msg: 'This phase will start shortly.', icon: 'info-circle' };
    } else if (isCompleted) {
      if (phase === IdsConstants.PHASES.ids_preprocessing.key) {
        return {
          msg: 'You can now proceed and run the job.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_initial_assessment.key) {
        return {
          msg: 'You can view details of the initial assessment in the job report, or proceed to the next phase.',
          icon: 'info-circle',
        };
      }
      if (phase === IdsConstants.PHASES.ids_final_assessment.key) {
        return {
          msg: 'You can view details of the final assessment or download the enhanced files.',
          icon: 'info-circle',
        };
      }
    }
  }

  if (processJob.statistics?.image_extraction_failed_message) {
    return {
      msg: processJob.statistics.image_extraction_failed_message,
      icon: 'question-circle',
    };
  }

  return null;
};

/**
 * Returns the current status icon of an IDS job
 *
 * @param {object} processJob process_job object
 *
 * @return status icon
 */
const getStatusIcon = (processJob) => {
  const iconMap = {
    [BotConstants.BOT_STATUSES.PENDING]: <FontAwesomeIcon icon={['fas', 'hourglass-start']} />,
    [BotConstants.BOT_STATUSES.RUNNING]: <FontAwesomeIcon icon={['fas', 'cog']} />,
    [BotConstants.BOT_STATUSES.COMPLETED]: <FontAwesomeIcon icon={['fas', 'check-circle']} />,
    [BotConstants.BOT_STATUSES.FAILED]: <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />,
    [BotConstants.BOT_STATUSES.REJECTED]: <FontAwesomeIcon icon={['fas', 'times-circle']} />,
    [BotConstants.BOT_STATUSES.UNDER_REVIEW]: <FontAwesomeIcon icon={['fas', 'eye']} />,
  };

  let output = null;

  const status = getStatus(processJob);

  if (status?.status) {
    output = (
      <span className={classNames('icon', status?.status)}>
        {status?.status in iconMap ? (
          iconMap[status?.status]
        ) : (
          <FontAwesomeIcon icon={['fas', 'circle']} />
        )}
      </span>
    );
  }

  return output;
};

/**
 * Checks whether number of files in archive or rows in sheet exceeds config max.
 *
 * @param {object} processJob process_job object
 *
 * @return flag
 */
const isMaxFileCount = (processJob) => {
  const maxCount = processJob?.config_data?.enhancement_images_limit ?? 1;

  const extractedCount = processJob?.statistics?.total_extracted ?? 0;
  const invalidCount = processJob?.statistics?.invalid_extracted ?? 0;

  return maxCount > 0 && extractedCount + invalidCount > maxCount;
};

/**
 * Retuns a list of allowed actions for an IDS job depending on state, phase and status
 *
 * @param {object} processJob process_job object
 *
 * @return list of allowed actions
 */
const getAllowedActions = (processJob) => {
  const output = [IdsConstants.ACTIONS.DETAILS];

  const phase = getPhase(processJob);
  const status = getStatus(processJob)?.status;

  if (processJob) {
    if (
      !processJob.set_for_run &&
      (!Object.keys(processJob?.bot_statuses)?.length ||
        phase === IdsConstants.PHASES.ids_preprocessing.key)
    ) {
      // New, not run yet, not set for run
      output.push(IdsConstants.ACTIONS.INITIAL_ASSESSMENT);
    } else if (status.toLowerCase().includes(BotConstants.BOT_STATUSES.COMPLETED)) {
      // After successful phase
      if (phase === IdsConstants.PHASES.ids_initial_assessment.key) {
        // After initial assessment
        output.push(IdsConstants.ACTIONS.ENHANCEMENT);
      }
    }
    if (
      !(
        [BotConstants.BOT_STATUSES.CANCELED].includes(status) ||
        (status === BotConstants.BOT_STATUSES.COMPLETED &&
          phase === IdsConstants.PHASES.ids_final_assessment.key) ||
        phase === IdsConstants.PHASES.ids_final_review.key ||
        phase === IdsConstants.PHASES.ids_quality_check.key
      )
    ) {
      output.push(IdsConstants.ACTIONS.CANCEL);
    }
    if (
      processJob?.bot_statuses?.ids_final_assessment?.status ===
        BotConstants.BOT_STATUSES.COMPLETED &&
      !processJob?.bot_statuses?.ids_quality_check &&
      (!processJob?.bot_statuses?.ids_final_review ||
        processJob?.bot_statuses?.ids_final_review?.status === BotConstants.BOT_STATUSES.APPROVED)
    ) {
      output.push(IdsConstants.ACTIONS.QUALITY_CHECK);
    }
  }
  return output;
};

const IdsUtils = {
  getPhase,
  getPhaseLabel,
  getStatus,
  getStatusLabel,
  getStatusIcon,
  isMaxFileCount,
  getAllowedActions,
  getTooltipInfo,
};

export { IdsUtils };
