import './DataLakehouseDashboard.scss';
import './DataLakehouseDashboardDistributor.scss';
import React, { useEffect, useState } from 'react';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import ActionStatusConstants from 'constants/ActionStatusConstants';
import { RootHooks } from 'helpers/RootHooks';
import { useIsMounted } from 'helpers/useIsMounted';

import * as DataLakehouseDashboardApiCalls from './private/DataLakehouseDashboardApiCalls';
import { SectionAssessments } from './private/SectionAssessments';
import { SectionDataJobs } from './private/SectionDataJobs';
import { SectionImageQuality } from './private/SectionImageQuality';
import { SectionSummaryCompany } from './private/SectionSummaryCompany';
import { SectionImgBotEngine } from './private/SectionImgBotEngine';

/**
 * Renders Data Lakehouse dashboard for Distributor role
 *
 * @return render
 */
const DataLakehouseDashboardDistributor = () => {
  const isMounted = useIsMounted();

  const { activeUser } = RootHooks.useActiveUser();

  const distributorData = activeUser?.profile?.client ?? null;

  // Holds data jobs data
  const [dataJobsData, setDataJobsData] = useState(null);
  const [dataJobsDataStatus, setDataJobsDataStatus] = useState(ActionStatusConstants.INITIAL);

  // Holds image quality assessment data
  const [imageQualityAssessmentData, setImageQualityAssessmentData] = useState([]);
  const [imageQualityAssessmentDataStatus, setImageQualityAssessmentDataStatus] = useState(
    ActionStatusConstants.INITIAL
  );

  // Refresh dashboard data on mfr id set
  useEffect(() => {
    DataLakehouseDashboardApiCalls.doGetDataJobs({
      isMounted,
      setDataJobsData,
      setDataJobsDataStatus,
    });

    DataLakehouseDashboardApiCalls.doGetImageQualityAssessment({
      id: distributorData.id,
      type: 'distributor',
      isMounted,
      setImageQualityAssessmentData,
      setImageQualityAssessmentDataStatus,
    });
  }, [isMounted, distributorData]);

  const isLoading = [imageQualityAssessmentDataStatus, dataJobsDataStatus].includes(
    ActionStatusConstants.ISBUSY
  );

  return (
    <div className="data-lakehouse-dashboard data-lakehouse-dashboard-distributor">
      <SectionSummaryCompany />
      {/* details section */}
      {isLoading ? (
        <div className="details-loading-wrapper">
          <LoadingSpinner fast style={{ fontSize: '3em', marginTop: '3em' }} />
        </div>
      ) : (
        <>
          <SectionImgBotEngine />

          {distributorData &&
          (imageQualityAssessmentData?.length > 0 ||
            (imageQualityAssessmentData?.length === 0 &&
              [ActionStatusConstants.ISBUSY, ActionStatusConstants.INITIAL].includes(
                imageQualityAssessmentDataStatus
              ))) ? (
            <SectionImageQuality
              type="distributor"
              id={distributorData.id}
              imageQualityAssessmentData={imageQualityAssessmentData}
              imageQualityAssessmentDataStatus={imageQualityAssessmentDataStatus}
            />
          ) : null}

          <SectionDataJobs
            dataJobsData={dataJobsData}
            dataJobsDataStatus={dataJobsDataStatus}
            showCreateBtn
            title="Most Recent Data Jobs"
          />

          {/* TODO: Delete this when releasing IDS above */}
          {/* <SectionAssessments title="Most Data File Recent Assessments" /> */}
        </>
      )}
    </div>
  );
};

export { DataLakehouseDashboardDistributor };
