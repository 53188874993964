import React, { useState } from 'react';

import './SectionImgBotEngine.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';

import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { ImgBotEngineDashboard } from 'components/img-bot-engine/ImgBotEngineDashboard';

import { IdsShortDescriptionBlock } from '../../../img-bot-engine/_private/IdsShortDescriptionBlock';
import { ImgBotEngineStats } from '../../../img-bot-engine/ImgBotEngineStats';

const SectionImgBotEngine = ({ showStats = false }) => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <section className="img-bot-engine">
      {showStats ? (
        <div>
          <ImgBotEngineStats />
        </div>
      ) : (
        <IdsShortDescriptionBlock />
      )}

      <div className="img-bot-engine-summary">
        <div className="heading">
          <div className="title">
            <span>Recent IDS Runs</span>
            {isLoading && <LoadingSpinner fast />}
          </div>
          <div className="actions">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => history.push(`/image-databot-suite${location?.search}`)}
            >
              <span className="label">View All</span>
              <span className="icon" style={{ marginLeft: '0.5em' }}>
                <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
              </span>
            </button>
          </div>
        </div>
        <ImgBotEngineDashboard
          onSetIsLoading={setIsLoading}
          itemsLimit={5}
          pageSize={5}
          showGetStarted
        />
      </div>
    </section>
  );
};

export { SectionImgBotEngine };
