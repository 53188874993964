import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { HeaderUtils } from './private/HeaderUtils';

const HeaderClient = ({ getRootPathItem }) => {
  const rootPathItem = getRootPathItem();

  return (
    <>
      <li
        className={HeaderUtils.getClassName(
          rootPathItem,
          'data-lakehouse-dashboard',
          getRootPathItem() === ''
        )}
      >
        <Link to="/">
          <span className="icon">
            <FontAwesomeIcon icon={['fas', 'home']} />
          </span>
          <span className="label">Home</span>
        </Link>
      </li>
      <li
        className={HeaderUtils.getClassName(
          rootPathItem,
          'data-requests',
          getRootPathItem() === ('data-jobs' || 'data-onboarding')
        )}
      >
        <Link to="/data-jobs">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'cloud-download']} />
          </span>
          <span className="label">Data Jobs</span>
        </Link>
      </li>
      <li className={HeaderUtils.getClassName(rootPathItem, 'image-databot-suite')}>
        <Link to="/image-databot-suite">
          <span className="icon">
            <FontAwesomeIcon icon={['fa', 'images']} />
          </span>
          <span className="label">IMAGE DATABOT SUITE</span>
        </Link>
      </li>
      {/*<li className={HeaderUtils.getClassName(rootPathItem, 'content-databot-suite')}>*/}
      {/*  <Link to="/content-databot-suite">*/}
      {/*    <span className="icon">*/}
      {/*      <FontAwesomeIcon icon={['fa', 'file-alt']} />*/}
      {/*    </span>*/}
      {/*    <span className="label">CONTENT DATABOT SUITE</span>*/}
      {/*  </Link>*/}
      {/*</li>*/}
      <li className={HeaderUtils.getClassName(rootPathItem, 'products')}>
        <Link to="/products">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'boxes-alt']} />
          </span>
          <span className="label">Products</span>
        </Link>
      </li>
      <li className={HeaderUtils.getClassName(rootPathItem, 'assessments')}>
        <Link to="/assessments">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'tachometer']} />
          </span>
          <span className="label">Assessments</span>
        </Link>
      </li>
      {/*
      TODO: uncomment when the first databot is ready
      <li className={HeaderUtils.getClassName(rootPathItem, 'databots')}>
        <Link to="/databots">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'cubes']} />
          </span>
          <span className="label">Databots</span>
        </Link>
      </li>*/}
      <li className={HeaderUtils.getClassName(rootPathItem, 'statistics')}>
        <Link to="/statistics">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'signal']} />
          </span>
          <span className="label">Metrics</span>
        </Link>
      </li>
    </>
  );
};

export { HeaderClient };
