import React from 'react';

import csvIcon from 'assets/img/file-formats/csv.svg';
import gifIcon from 'assets/img/file-formats/gif.svg';
import jpegIcon from 'assets/img/file-formats/jpeg.svg';
import pngIcon from 'assets/img/file-formats/png.svg';
import tiffIcon from 'assets/img/file-formats/tiff.svg';
import webpIcon from 'assets/img/file-formats/webp.svg';
import xlsIcon from 'assets/img/file-formats/xls.svg';
import xlsxIcon from 'assets/img/file-formats/xlsx.svg';
import zipIcon from 'assets/img/file-formats/zip.svg';

import { CustomBotConfigUtils } from './CustomBotConfigUtils';

const BG_COLOR_OPTIONS = {
  transparent: {
    key: 'transparent',
    label: 'Transparent',
    rgba: { r: 0, g: 0, b: 0, a: 0 },
    hex: '#00000000',
  },
  black: { key: 'black', label: 'Black', rgba: { r: 0, g: 0, b: 0, a: 1 }, hex: '#000000FF' },
  white: { key: 'white', label: 'White', rgba: { r: 255, g: 255, b: 255, a: 1 }, hex: '#FFFFFFFF' },
  custom: {
    key: 'custom',
    label: 'Custom',
    rgba: { r: 128, g: 128, b: 128, a: 1 },
    hex: '#808080FF',
  },
};

const CUSTOM_BOT_CFG_TYPES = {
  checkbox: { key: 'checkbox' },
  number: { key: 'number' },
  percentage: { key: 'percentage' },
  option: { key: 'option' },
  color_picker_select: { key: 'color_picker_select' },
  textfield: {
    key: 'textfield',
    subtypes: {
      password: { key: 'password' },
    },
  },
};

const CUSTOM_BOT_CFG_GROUPS = {
  // assessment: {
  //   key: 'assessment',
  //   label: 'Assessment Modules',
  //   helperText:
  //     'Toggle analysis modules that you want to include or skip in the Initial and Final Assessment.',
  // },
  bool_params: {
    key: 'bool_params',
    label: 'Flags',
    helperText: 'Override default Assessment module flags.',
  },

  params: {
    key: 'params',
    label: 'Parameters',
    helperText: 'Override default Assessment module parameters.',
  },
  detection: {
    key: 'detection',
    label: 'Detection Confidence Thresholds',
    helperText: (
      <>
        These define the minimum confidence levels required for the AI detection modules to accept
        specific detections as valid. <br /> By adjusting these thresholds, you can control the
        accuracy and precision of the AI assessments, ensuring that only reliable results are
        considered for your images. Each module has its own threshold, allowing you to fine-tune the
        confidence levels according to your needs. <br />
        <strong>Higher thresholds</strong> result in stricter detection criteria, while{' '}
        <strong>lower thresholds</strong> provide a more lenient approach.
      </>
    ),
  },
};

const CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS = {
  enhancement: {
    key: 'enhancement',
    label: 'Modules',
    helperText: 'Select the enhancement modules you wish to apply to your images.',
  },
  params: {
    key: 'params',
    label: 'Parameters',
    helperText: 'Override default module parameters.',
  },
  output: {
    key: 'output',
    label: 'Output',
    helperText: 'Override default output parameters.',
  },
};

const CUSTOM_ENHANCEMENT_BOT_CFG_MODEL = {
  enlargement: {
    key: 'enlargement_is_enabled',
    attrKey: 'enlargement',
    valueKey: 'is_enabled',
    get: (o) => o?.enlargement?.is_enabled,
    update: (o, v, attrKey, valueKey) => CustomBotConfigUtils.update({ o, v, attrKey, valueKey }),
    label: 'Enlargement',
    title: 'Enlargement (2X/4X)',
    tooltip: (
      <>
        The IDS uses a neural network to upscale images that need it. <br /> It determines the
        upscaling factor (<strong>x2</strong> or <strong>x4</strong>) based on the{' '}
        <u>longest dimension (width or height)</u> of the image. If the longest dimension is{' '}
        <strong>below 1000 pixels</strong>, it is upscaled by <strong>x4</strong>. If it falls{' '}
        <strong>between 1000 and 1800 pixels</strong>, it is upscaled by <strong>x2</strong>. <br />
        Images with a longest dimension <strong>larger than 1800 pixels</strong> are{' '}
        <u>not upscaled</u>.
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  bg_removal: {
    get: (o) => o?.bg_removal?.is_enabled,
    attrKey: 'bg_removal',
    valueKey: 'is_enabled',
    update: (o, v, attrKey, valueKey) => CustomBotConfigUtils.update({ o, v, attrKey, valueKey }),
    label: 'Background Removal',
    title: 'Background Removal/Replacement',
    tooltip: (
      <>
        The IDS uses a proprietary neural network that identifies the product and background pixels
        allows you to either <u>replace the background pixels</u> with a solid color or completely{' '}
        <u>remove them by adding a transparency layer</u>.
        <br />
        You can choose the color in the <strong>Background Color</strong> setting.
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  bg_model_type: {
    key: 'bg_model_type',
    attrKey: 'bg_removal',
    valueKey: 'ml_model_type',
    get: (o) => o?.bg_removal?.ml_model_type,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
        type: 'any',
      }),
    label: 'BG Model Type',
    title: 'BG Model Type',
    unitLabel: '',
    tooltip: (
      <>
        The <strong>BG Model Type</strong> option determines the variant of the neural network that
        is used for the background removal task. <br />
        <ul>
          <li>
            <strong>Generic</strong> is the default option and is suitable for most use cases.{' '}
            <br />
          </li>
          <li>
            <strong>Eyewear / Glasses</strong> is a specialized variant that is optimized for images
            of eyewear products. <br />
          </li>
        </ul>
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.option.key,
    options: [
      { value: 'generic', label: 'Generic' },
      { value: 'bg_removal_glasses', label: 'Eyewear / Glasses' },
    ],
  },
  bg_removal_value: {
    key: 'bg_removal_value',
    attrKey: 'bg_removal',
    valueKey: 'value',
    get: (o) => o?.bg_removal?.value,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
      }),
    isDisabled: (o) => !o?.bg_removal?.is_enabled,
    label: 'Background Color',
    title: 'Background Replacement Color',
    unitLabel: undefined,
    tooltip: (
      <>
        Allows you to specify the color used for the <strong>Background Removal</strong> enhancement
        module. Options include <strong>transparent</strong>, <strong>white</strong>,{' '}
        <strong>black</strong> or a custom color of your choice.
        <br />
        This setting determines the color applied to the background of the enhanced images.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.color_picker_select.key,
    options: Object.values(BG_COLOR_OPTIONS),
  },
  method_name: {
    key: 'method_name',
    attrKey: 'enlargement',
    valueKey: 'method_name',
    get: (o) => o?.enlargement?.method_name,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
        type: 'any',
      }),
    label: 'Enlargement Method',
    title: 'Enlargement Method',
    unitLabel: '',
    tooltip: (
      <>
        Allows you to choose the algorithm used for enlarging images. Different algorithms produce
        varying results in terms of image quality and sharpness. Select the algorithm that best
        suits your image upscaling needs.
        <br />
        Available methods:
        <br />
        <ul>
          <li>
            <strong>AI-Powered: </strong>Leverages advanced artificial intelligence for the highest
            quality upscaling results, albeit at a slower pace, making it ideal for detailed images.
            <br />
          </li>
          <li>
            <strong>Bicubic: </strong>Balances smoothness and sharpness, offering decent speed and
            preserving some image details during upscaling.
            <br />
          </li>
          <li>
            <strong>Nearest Neighbor:</strong> Fast and sharp upscaling, making it perfect for pixel
            art and graphics with distinct edges.
            <br />
          </li>
          <li>
            <strong>Bilinear:</strong> Produces a relatively quick and smoother enlargement,
            suitable for most images and general purposes.
            <br />
          </li>
          <li>
            <strong>Lanczos: </strong>Delivers the highest quality upscaling with exceptional
            sharpness and detail retention, although it may take more time, making it ideal for
            photographs and high-resolution images.
            <br />
          </li>
          <br />
        </ul>
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.option.key,
    options: [
      { label: 'AI-Powered', value: 'ai_large' },
      { label: 'Bicubic', value: 'bicubic' },
      { label: 'Nearest Neighbor', value: 'nearest_neighbor' },
      { label: 'Bilinear', value: 'bilinear' },
      { label: 'Lanczos', value: 'lanczos' },
    ],
  },
  cropping: {
    key: 'cropping_is_enabled',
    attrKey: 'cropping',
    valueKey: 'is_enabled',
    get: (o) => o?.cropping?.is_enabled,
    update: (o, v, attrKey, valueKey) => CustomBotConfigUtils.update({ o, v, attrKey, valueKey }),
    label: 'Cropping',
    tooltip: (
      <>
        The IDS intelligently crops the image to <u>perfectly fit the product</u>, while also{' '}
        preserving a specified amount of <u>padding around the object</u>. The amount of padding can
        be adjusted using the <strong>Crop Padding(%)</strong> configuration option.
        <br />
        This ensures that the product remains the focal point while providing a visually pleasing
        composition.
      </>
    ),
    title: 'Automatic Cropping',
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  cropping_value: {
    key: 'cropping_value',
    attrKey: 'cropping',
    valueKey: 'value',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.cropping?.value),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    isDisabled: (o) => !o?.cropping?.is_enabled,
    getErrMsg: (o) => (!o?.cropping?.is_enabled ? undefined : o?.cropping?.err_value),
    label: 'Crop Padding',
    title: 'Padding for Cropping',
    unitLabel: '%',
    tooltip: (
      <>
        The amount of padding to apply to the image when <strong>Cropping</strong> is enabled. The
        padding is applied as a percentage of the <u>width of the product</u>.
        <br />
        For example, if the product is <strong>1000 pixels</strong> wide and the padding percentage
        is <strong>10%</strong>, the product will be padded with <strong>100 pixels</strong>.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  keep_duplicates: {
    key: 'keep_duplicates_is_enabled',
    attrKey: 'keep_duplicates',
    valueKey: 'is_enabled',
    get: (o) => o?.keep_duplicates?.is_enabled,
    update: (o, v, attrKey, valueKey) => CustomBotConfigUtils.update({ o, v, attrKey, valueKey }),

    label: 'Keep Duplicates',
    title: 'Keep Duplicates (apply enhancements)',
    tooltip: (
      <>
        The IDS allows you to choose whether to retain or remove detected duplicate images. The IDS{' '}
        <u>prioritizes the highest-quality image</u> when deciding which duplicates to keep.
        <br />
        For example, if there are three identical images with different resolutions (300x200,
        600x400, and 1200x800), the IDS will keep the highest-resolution image (1200x800) and
        discard the others.
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  resolution_correction: {
    key: 'resolution_correction_is_enabled',
    attrKey: 'resolution_correction',
    valueKey: 'is_enabled',
    get: (o) => o?.resolution_correction?.is_enabled,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
      }),
    label: 'Overwrite Resolution',
    title: 'Overwrite Resolution',
    tooltip: (
      <>
        IDS allows you to correct the DPI Resolution metadata fields (<strong>XResolution</strong>{' '}
        and <strong>YResolution</strong>) of an image. You can set or replace the DPI value based on
        your preference.
        <br />
        Specify the desired DPI value using the <strong>Target Resolution(DPI)</strong>{' '}
        configuration option.
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  resolution_correction_value: {
    key: 'resolution_correction_value',
    attrKey: 'resolution_correction',
    valueKey: 'value',
    get: (o) => o?.resolution_correction?.value,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'integer',
        positiveOnly: true,
      }),
    isDisabled: (o) => !o?.resolution_correction?.is_enabled,
    getErrMsg: (o) =>
      !o?.resolution_correction?.is_enabled ? undefined : o?.resolution_correction?.err_value,
    label: 'Target Resolution',
    unitLabel: 'DPI',
    tooltip: (
      <>
        The desired DPI value to set or replace the DPI Resolution metadata fields (
        <strong>XResolution</strong> and <strong>YResolution</strong>) of an image.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.number.key,
  },
  format_conversion: {
    key: 'format_conversion_is_enabled',
    attrKey: 'format_conversion',
    valueKey: 'is_enabled',
    get: (o) => o?.format_conversion?.is_enabled,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
      }),
    label: 'Format Conversion',
    title: 'Format Conversion',
    tooltip: (
      <>
        IDS allows you to convert images to a specific format. Specify the desired format using the{' '}
        <strong>Output Format</strong> configuration option.
      </>
    ),

    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.enhancement.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  format_conversion_value: {
    key: 'format_conversion_value',
    attrKey: 'format_conversion',
    valueKey: 'value',
    get: (o) => o?.format_conversion?.value,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'any',
      }),
    label: 'Output Format',
    title: 'Conversion / Output Format',
    unitLabel: '',
    tooltip: (
      <>
        The <strong>Output Format</strong> option determines the desired format for saving the
        output images. Depending the state of <strong>Format Conversion</strong> enhancement, the
        IDS will do one of the following:
        <ul>
          <li>
            If the <strong>Format Conversion</strong> enhancement is enabled, the IDS will convert
            all images that do not match the specified format.
          </li>
          <li>
            If the <strong>Format Conversion</strong> enhancement is disabled, the IDS will only
            save the enhanced images in the specified format.
          </li>
        </ul>
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.output.key,
    type: CUSTOM_BOT_CFG_TYPES.option.key,
    options: [
      { value: 'png', label: 'PNG' },
      { value: 'jpeg', label: 'JPEG' },
      { value: 'webp', label: 'WEBP' },
      { value: 'tiff', label: 'TIFF' },
    ],
  },
  split_outputs: {
    key: 'split_outputs_is_enabled',
    attrKey: 'split_outputs',
    valueKey: 'is_enabled',
    get: (o) => o?.split_outputs?.is_enabled,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.update({
        o,
        v,
        attrKey,
        valueKey,
      }),
    label: 'Split Outputs',
    title: 'Split Output Images',
    tooltip: (
      <>
        Enable this option to split the output images into two distinct folders based on their
        market readiness. The folders will be labeled <strong>Market Ready</strong> and{' '}
        <strong>Needs Work</strong>.
      </>
    ),
    group: CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS.output.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
};

const CUSTOM_BOT_CFG_MODEL = {
  min_image_size_a: {
    key: 'min_image_size_a',
    attrKey: 'min_image_size',
    valueKey: 'size_a',
    get: (o) => o?.min_image_size?.size_a,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'integer',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.min_image_size?.err_size_a,
    label: 'Min. Longest Dimension',
    unitLabel: 'px',
    title: 'Minimum Longest Dimension',
    tooltip: (
      <>
        The minimum accepted value for the longest dimension of your image. The assessment evaluates
        if the longest side meets or exceeds the specified value.
        <br />
        Images with a longest dimension below the defined threshold will be considered insufficient
        for market-ready purposes and may require enhancement.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.number.key,
  },
  blur_assessment: {
    key: 'blur_assessment',
    attrKey: 'blur_assessment',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.blur_assessment?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.blur_assessment?.err_threshold,
    label: 'Blur Acceptance',
    unitLabel: '%',
    tooltip: (
      <>
        The maximum acceptable level of blurriness in your images. The assessment evaluates the
        amount of blur present in each image, ensuring that it stays within the specified threshold.
        <br />
        Images with a blurriness level exceeding the defined threshold will be considered unsuitable
        for market-ready purposes.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  is_shortest_mandatory: {
    key: 'is_shortest_mandatory',
    attrKey: 'min_image_size',
    valueKey: 'is_shortest_mandatory',
    get: (o) => o?.min_image_size?.is_shortest_mandatory,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
      }),
    getErrMsg: (o) => o?.min_image_size?.err_size_b,
    label: 'Min. Shortest Dimension',
    unitLabel: 'px',
    title: 'Minimum Shortest Dimension',
    tooltip: (
      <>
        The minimum accepted value for the shortest dimension of your image. The assessment
        evaluates if the shortest side meets or exceeds the specified value.
        <br />
        Images with a shortest dimension below the defined threshold will be considered insufficient
        for market-ready purposes and may require enhancement.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
    children: [
      {
        key: 'min_image_size_b',
        attrKey: 'min_image_size',
        valueKey: 'size_b',
        get: (o) => o?.min_image_size?.size_b,
        update: (o, v, attrKey, valueKey) =>
          CustomBotConfigUtils.validateAndUpdate({
            o,
            v,
            attrKey,
            valueKey,
            type: 'integer',
            positiveOnly: true,
          }),
        getErrMsg: (o) => o?.min_image_size?.err_size_b,
        tooltip: (
          <>
            The minimum accepted value for the shortest dimension of your image. The assessment
            evaluates if the shortest side meets or exceeds the specified value.
            <br />
            Images with a shortest dimension below the defined threshold will be considered
            insufficient for market-ready purposes and may require enhancement.
          </>
        ),
        isDisabled: (o) => !o?.min_image_size?.is_shortest_mandatory,
        group: CUSTOM_BOT_CFG_GROUPS.params.key,
        type: CUSTOM_BOT_CFG_TYPES.number.key,
      },
    ],
  },
  centered_assessment: {
    key: 'centered_assessment',
    attrKey: 'centered_assessment',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.centered_assessment?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.centered_assessment?.err_threshold,
    label: 'Product Alignment Threshold',
    unitLabel: '%',
    tooltip: (
      <>
        Determines the acceptable percentage{' '}
        <u>offsets between the center of a product and the center of the image</u>, ensuring that
        the product is well-aligned for e-commerce display. <br />
        By setting the threshold, you can define the allowed deviation from the center, with images
        exceeding the limit flagged as misaligned. A <strong>lower</strong> threshold requires{' '}
        <strong>tighter alignment</strong>, while a <strong>higher threshold</strong> allows for{' '}
        <strong>greater deviation</strong> from the center.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  min_image_ppi: {
    key: 'min_image_ppi',
    attrKey: 'min_image_ppi',
    valueKey: 'threshold',
    get: (o) => o?.min_image_ppi?.threshold,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'integer',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.min_image_ppi?.err_threshold,
    label: 'Min. Image Resolution',
    unitLabel: 'DPI',
    title: 'Minimum Image Resolution',
    tooltip: (
      <>
        The threshold for the minimum acceptable DPI (dots-per-inch) resolution of your images. This
        configuration option ensures that the <strong>XResolution</strong> and{' '}
        <strong>YResolution</strong> metadata fields meet the specified DPI value.
        <br />
        Images with DPI values below the defined threshold will be deemed insufficient for
        market-ready purposes and may require enhancement.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.number.key,
  },
  correctly_cropped: {
    key: 'correctly_cropped',
    attrKey: 'correctly_cropped',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.correctly_cropped?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.correctly_cropped?.err_threshold,
    label: 'Padding Acceptance',
    unitLabel: '%',
    tooltip: (
      <>
        Determines the acceptable percentage of{' '}
        <u>space between the edges of the product and the edges of the image</u> to determine if the
        product is properly cropped for e-commerce display. <br />
        By setting the threshold, you can define the acceptable percentage of space surrounding the
        product, with images exceeding the limit flagged as incorrectly cropped. A{' '}
        <strong>lower threshold</strong> requires tighter cropping, while a{' '}
        <strong>higher threshold</strong> allows for more space around the product.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  allow_multiple_products: {
    key: 'multiple_products_is_allowed',
    attrKey: 'multiple_products',
    valueKey: 'is_allowed',
    get: (o) => o?.multiple_products?.is_allowed,
    update: (o, v, attrKey, valueKey) => CustomBotConfigUtils.update({ o, v, attrKey, valueKey }),
    label: 'Allow Multiple Products',
    tooltip: (
      <>
        Enabling this option will allow the IDS to consider images with <u>multiple items</u> as
        valid for market-ready purposes.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.bool_params.key,
    type: CUSTOM_BOT_CFG_TYPES.checkbox.key,
  },
  watermark_detection: {
    key: 'watermark_detection',
    attrKey: 'watermark_detection',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.watermark_detection?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.watermark_detection?.err_threshold,
    label: 'Watermark Detection',
    unitLabel: '%',
    tooltip: (
      <>
        Identifies the presence of watermarks or logos on your images, ensuring a clean and
        distraction-free visual for e-commerce purposes. By adjusting the <u>threshold</u>, you can
        control the sensitivity of watermark detection, balancing false positives and missed
        detections.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.detection.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  diagram_detection: {
    key: 'diagram_detection',
    attrKey: 'diagram_detection',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.diagram_detection?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.diagram_detection?.err_threshold,
    label: 'Diagram Detection',
    unitLabel: '%',
    tooltip: (
      <>
        Recognizes images that depict diagrams, charts, or graphs. <br />
        The <u>threshold</u> setting determines the confidence level required for a detection,
        allowing you to adjust the strictness of diagram identification.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.detection.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  person_detection: {
    key: 'person_detection',
    valueKey: 'threshold',
    attrKey: 'person_detection',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.person_detection?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.person_detection?.err_threshold,
    label: 'Person Detection',
    unitLabel: '%',
    tooltip: (
      <>
        Detects any individuals appearing in your product images, helping you maintain a clear focus
        on the product itself without any unwanted subjects. Changing the <u>threshold</u> affects
        the accuracy of person detection, allowing you to fine-tune detection sensitivity.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.detection.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
  placeholder_detection: {
    key: 'placeholder_detection',
    attrKey: 'placeholder_detection',
    valueKey: 'threshold',
    get: (o) => CustomBotConfigUtils.formatPercentage(o?.placeholder_detection?.threshold),
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'percentage',
        positiveOnly: true,
      }),
    getErrMsg: (o) => o?.placeholder_detection?.err_threshold,
    label: 'Placeholder Detection',
    unitLabel: '%',
    tooltip: (
      <>
        Detects if an image contains a placeholder element, such as text or symbols, indicating a
        temporary image.
        <br />
        Modifying the <u>threshold</u> influences the confidence level necessary for detecting
        placeholders, helping you find the right balance between precision and accuracy.
      </>
    ),
    group: CUSTOM_BOT_CFG_GROUPS.detection.key,
    type: CUSTOM_BOT_CFG_TYPES.percentage.key,
  },
};

const EXTERNAL_DELIVERY_CFG_GROUPS = {
  params: {
    key: 'params',
    label: 'Parameters',
    helperText:
      'Please provide the information needed to connect and deliver the artifacts to the specified destination.',
  },
};

const EXTERNAL_DELIVERY_FTP_CFG_MODEL = {
  username: {
    key: 'username',
    attrKey: 'credentials',
    valueKey: 'username',
    get: (o) => o?.credentials?.username,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'any',
      }),
    getErrMsg: (o) => o?.credentials?.err_username,
    label: 'Username',
    title: 'Username',
    unitLabel: '',
    tooltip: <>The username to use for authenticating to the FTP server</>,
    group: EXTERNAL_DELIVERY_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.textfield.key,
  },
  password: {
    key: 'password',
    attrKey: 'credentials',
    valueKey: 'password',
    get: (o) => o?.credentials?.password,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'any',
      }),
    getErrMsg: (o) => o?.credentials?.err_password,
    label: 'Password',
    title: 'Password',
    unitLabel: '',
    tooltip: <>The password to use for authenticating to the FTP server</>,
    group: EXTERNAL_DELIVERY_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.textfield.key,
    subtype: CUSTOM_BOT_CFG_TYPES.textfield.subtypes.password.key,
  },
  host: {
    key: 'host',
    attrKey: 'credentials',
    valueKey: 'host',
    get: (o) => o?.credentials?.host,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'custom',
        validationFn: CustomBotConfigUtils.validateHostName,
      }),
    getErrMsg: (o) => o?.credentials?.err_host,
    label: 'Host',
    title: 'Host',
    unitLabel: '',
    tooltip: <>The name or IP of the FTP host.</>,
    group: EXTERNAL_DELIVERY_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.textfield.key,
  },
  location: {
    key: 'location',
    attrKey: 'credentials',
    valueKey: 'location',
    get: (o) => o?.credentials?.location,
    update: (o, v, attrKey, valueKey) =>
      CustomBotConfigUtils.validateAndUpdate({
        o,
        v,
        attrKey,
        valueKey,
        type: 'custom',
        validationFn: (value) => {
          return CustomBotConfigUtils.validateLocation(value);
        },
      }),
    getErrMsg: (o) => o?.credentials?.err_location,
    label: 'Folder Name',
    title: 'Folder Name',
    unitLabel: '',
    tooltip: (
      <>
        The name of the folder where the artifacts will be exported to. If the folder does not
        exist, it will be created. The artifacts will be saved in a subfolder named{' '}
        <code>job_&lt;job_id&gt;_artifacts</code>
      </>
    ),
    group: EXTERNAL_DELIVERY_CFG_GROUPS.params.key,
    type: CUSTOM_BOT_CFG_TYPES.textfield.key,
  },
};

const ASSESSMENT_MODEL = {
  pass_images: {
    key: 'pass_images',
    label: 'Market Ready',
    description: <>Number of images that have passed all assessments.</>,
  },
  fail_images: {
    key: 'fail_images',
    label: 'Needs Work',
    description: <>Number of images that have failed at least one assessment.</>,
    reversed: true,
  },
  total_items: {
    key: 'total_items',
    label: 'Total Images',
    description: <>Total number of images that have been provided.</>,
  },
  completed_items: {
    key: 'completed_items',
    label: 'Processed Images',
    description: <>Number of images that have been processed successfully.</>,
  },
  failed_items: {
    key: 'failed_items',
    label: 'Failed Images',
    description: (
      <>
        <p>Number of images where the processing has failed for unknown reasons.</p>
      </>
    ),
    reversed: true,
  },
  invalid_items: {
    key: 'invalid_items',
    label: 'Rejected Images',
    description: <>Number of images that have been rejected.</>,
    reversed: true,
  },
  not_provided_items: {
    key: 'not_provided_items',
    label: 'Missing Items',
    description: (
      <>
        Number of images that could not be found. Applies to URL files, where the column holding the
        URLs has empty cells.
      </>
    ),
    reversed: true,
  },
  is_transformed: {
    key: 'is_transformed',
    label: 'Enhanced Images',
    description: <>Number of images that have been enhanced by the IDS.</>,
  },
  has_background: { key: 'has_background', label: 'Has Background' },
  has_multiple_products: {
    key: 'has_multiple_products',
    label: 'Has Multiple Items',
    description: 'Images that contain more than one item.',
    reversed: true,
  },
  has_watermark: {
    key: 'has_watermark',
    label: 'Watermarked',
    description: 'Images where a watermark has been detected.',
    reversed: true,
  },
  has_person: {
    key: 'has_person',
    label: 'Person Detected',
    description: 'Images where a person or a body part was detected.',
    reversed: true,
  },
  is_blurry: {
    key: 'is_blurry',
    label: 'Blurry',
    description: 'Images that are blurry.',
    reversed: true,
  },
  is_centered: {
    key: 'is_centered',
    label: 'Centered',
    description: 'Images where the product is correctly centered.',
  },
  is_duplicated: {
    key: 'is_duplicated',
    label: 'Duplicated',
    description: 'Images that have been detected as duplicates of other images.',
  },
  is_min_image_resolution: {
    key: 'is_min_image_resolution',
    label: 'Min. Resolution',
    description: 'Images that meet the minimum resolution(DPI) requirement.',
  },
  is_min_image_size: {
    key: 'is_min_image_size',
    label: 'Min. Size',
    description: 'Images that meet the minimum size(px) requirement.',
  },
  is_perfectly_cropped: {
    key: 'is_perfectly_cropped',
    label: 'Correctly Cropped',
    description: 'Images where the product is correctly cropped.',
  },
  is_placeholder: {
    key: 'is_placeholder',
    label: 'Placeholder',
    description: 'Images that have been detected as placeholders.',
    reversed: true,
  },
  is_schema: {
    key: 'is_schema',
    label: 'Diagram',
    description: 'Images that have been detected as diagrams.',
    reversed: true,
  },
};

const ASSESSMENT_SUMMARY_MODEL = {
  market_ready: {
    key: 'market_ready',
    dataKey: 'pass_images',
    label: 'Market Ready',
    showPercentage: true,
  },
  needs_work: {
    key: 'needs_work',
    dataKey: 'fail_images',
    label: 'Needs Work',
    showPercentage: true,
  },
  rejected: { key: 'rejected', dataKey: 'invalid_items', label: 'Rejected', showPercentage: true },
  total: { key: 'total', dataKey: 'total_items', label: 'Total Images', showPercentage: false },
};

const FAQ_ITEMS = [
  {
    question: 'How do I make sure my results are high quality?',
    answer: (
      <>
        We recommend reviewing the image databot configuration file and check the settings against
        your requirements. You can adjust and "tune" the configuration file for the best possible
        results.
      </>
    ),
  },
  {
    question: 'How do I configure my IDS job?',
    answer: (
      <>
        From the IDS job listing screen click the "configurations" button. You can create new
        configurations. If you wish to change a configuration, go into the IDS job and adjust the
        configurations to your desired settings.
      </>
    ),
  },
  {
    question: 'How do I use the same images for multiple jobs?',
    answer: (
      <>
        You can upload the same images as many times as you like! Create your IDS job and add the
        images using the upload procedures.
      </>
    ),
  },
  {
    question: 'What are good image criteria that work for all distributors?',
    answer: (
      <>
        Our default configuration settings are based upon work with almost 100 distributors. These
        configurations can be adjusted or "tuned" based on your specific requirements.
      </>
    ),
  },
  {
    question: 'What if I don’t like my results?',
    answer: (
      <>
        You can re-run IDS on the same images and adjust the configuration settings to improve your
        results.
      </>
    ),
  },
  {
    question: 'How do I share my results?',
    answer: (
      <>
        You can download the results through the "files" button and share using your preferred
        methods.
      </>
    ),
  },
  {
    question: 'How do I upload new images into my own ERP?',
    answer: (
      <>
        Every ERP has specific settings for adding image files. Please refer to your ERP provider
        for detailed instructions.
      </>
    ),
  },
  {
    question: 'Can I deliver my images to multiple companies?',
    answer: (
      <>
        As long as the images are authorized to share, you can deliver to the companies that need
        the images.
      </>
    ),
  },
  {
    question: 'How do I invite other people at my company to use BackboneAI?',
    answer: (
      <>
        Have your team click <a href="https://platform.backbone.ai/sign-up-request">here</a> to sign
        up!
      </>
    ),
  },
  {
    question: 'Do I need to be technical to use BackboneAI?',
    answer: (
      <>
        No technical skills are needed to use BackboneAI. If you are familiar with basic internet
        functions, you can use BackboneAI!
      </>
    ),
  },
  {
    question: 'What AI technology does BackboneAI use?',
    answer: (
      <>
        BackboneAI uses advanced machine learning and automation tools, built on a robust and secure
        Microsoft Azure platform.
      </>
    ),
  },
  {
    question: 'Do I have to worry about copyrights for the images?',
    answer: (
      <>
        Any copyrights or authorizations that the original images have would carry forward to the
        images processed by IDS.
      </>
    ),
  },
  {
    question: 'Are my images proprietary or are they shared?',
    answer: (
      <>
        Your images that are processed through your account have the same copyrights, ownership, and
        authorizations as the original images. Consult your legal agreements to determine sharing
        privileges.
      </>
    ),
  },
  {
    question: 'How do I spot check image processing?',
    answer: <>In the IDS results screen you can see a sampling of "before" and "after" images.</>,
  },
  {
    question: 'When do I know I should get new source images?',
    answer: (
      <>
        It is recommended to review your images at least once a year to determine if an update is
        needed.
      </>
    ),
  },
  {
    question: 'Can you explain how the scores work for images?',
    answer: (
      <>
        The image assessment report provides insights on as many as 15 different checks including
        size, blurriness, background, and more.
      </>
    ),
  },
  {
    question: 'What are the hardest image problems to correct?',
    answer: (
      <>
        Some of the most challenging problems are upscaling or enlarging images that are too small
        or too blurry. This can require configuration adjustments to help improve the results.
      </>
    ),
  },
  {
    question:
      'Are there certain product categories that are better than others for image processing?',
    answer: (
      <>
        Each type of product has its own unique challenges to have the best possible results. It is
        recommended to review the results and if needed adjust the configurations.
      </>
    ),
  },
  {
    question: 'Where can I learn more about how image automation works?',
    answer: (
      <>
        We are happy to schedule a review session with you to talk about the BackboneAI automation
        solutions. Contact us at{' '}
        <a href="mailto:data@backbone.ai?subject=I%20Want%20To%20Learn%20More&body=Hi%20BackboneAI%20Team%2C%0A%0A">
          data@backbone.ai
        </a>{' '}
        for more information.
      </>
    ),
  },
  {
    question: 'Why is my image still blurry?',
    answer: (
      <>
        If the image size is extremely small, it may not be possible to improve the quality of the
        image. Also, if the image was extremely blurry, the automation may not be able to make the
        corrections needed.
        <br />
        Finally, the configuration settings used to enhance the image may need to be adjusted. Try
        adjusting the configuration.
      </>
    ),
  },
  {
    question: 'How can I "force" the enhancement process to take place?',
    answer: (
      <>
        Configurations are a powerful tool when it comes to providing the requirements to the IDS,
        but if in any case some images are marked as &quot;pass&quot; in the initial assessment,
        they will not be enhanced any further.
        <br />
        The solution in this case would be to adjust the assessment config in a way in which all of
        your images will be marked as failed so the enhancement process will be triggered.
      </>
    ),
  },
  {
    question: (
      <>
        Enlargement was applied to my images, but they are still failing{' '}
        <strong>Min. Image Size</strong>. Why?
      </>
    ),
    answer: (
      <>
        Our IDS uses a sophisticated neural network to enlarge your images, applying an upscaling
        factor (x2 or x4) based on the longest dimension (width or height) of your image.
        <ul>
          <li>
            If the longest dimension of your image is <u>below 1000 pixels</u>, IDS applies an
            upscaling factor of <strong>x4</strong>.
          </li>
          <li>
            If the longest dimension is <u>between 1000 and 1800 pixels</u>, it applies an upscaling
            factor of <strong>x2</strong>.
          </li>
          <li>
            However, images with a longest dimension <u>larger than 1800 pixels</u> are{' '}
            <strong>not upscaled</strong>.
          </li>
        </ul>
        If your images are still failing the <strong>Min. Image Size</strong> criteria, it might be
        due to the original image size being too small. Even after applying the x4 upscaling, if
        your initial image size is significantly small, the final image may still be below the
        minimum required size.
        <br />
        Another reason could be the longest dimension of the image being over 1800 pixels, in which
        case, no upscaling would be applied by IDS.
      </>
    ),
  },
  {
    question: (
      <>
        My IDS run is stuck in <strong>Pending</strong>. What should I do?
      </>
    ),
    answer: (
      <>
        Don&apos;t worry, your IDS run is not lost or ignored. The <strong>IDS</strong> operates
        using asynchronous workers which process each run. These runs are placed in a queue and are
        picked up by a worker to be processed in due course.
        <br />
        When there are a lot of runs in the queue, it might take some time before your IDS run moves
        from <code>Pending</code> to <code>Running</code>. This is a normal part of the process and
        ensures that every user&apos;s run gets the dedicated resources it requires.
        <br />
        In times of high demand, our infrastructure auto-scales to handle the increased load. This
        scaling operation might take a couple of minutes, but it ensures that your IDS run will be
        processed as swiftly as possible once the necessary resources become available.
        <br />
        Please be patient and give the system some time to process your request. If your IDS run
        continues to be stuck in <code>Pending</code> for an extended period of time, please contact
        our support team for further assistance.
      </>
    ),
  },
];

const IDS_ACCEPTED_FILE_TYPES = [
  { name: '.zip', icon: zipIcon },
  { name: '.csv', icon: csvIcon },
  { name: '.xlsx', icon: xlsxIcon },
  { name: '.xls', icon: xlsIcon },
];

const IDS_SUPPORTED_IMAGE_TYPES = [
  { name: '.jpeg', icon: jpegIcon },
  { name: '.png', icon: pngIcon },
  { name: '.tiff', icon: tiffIcon },
  { name: '.webp', icon: webpIcon },
  { name: '.gif', icon: gifIcon },
];

const IDS_EXTERNAL_DELIVERY_MODELS = {
  ftp: {
    credentials: {
      username: '',
      password: '',
      host: '',
      location: '',
    },
    configGroup: EXTERNAL_DELIVERY_CFG_GROUPS,
    configModel: EXTERNAL_DELIVERY_FTP_CFG_MODEL,
  },
};
const IdsModels = {
  CUSTOM_BOT_CFG_GROUPS,
  CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS,
  CUSTOM_ENHANCEMENT_BOT_CFG_MODEL,
  CUSTOM_BOT_CFG_MODEL,
  EXTERNAL_DELIVERY_CFG_GROUPS,
  EXTERNAL_DELIVERY_FTP_CFG_MODEL,
  ASSESSMENT_MODEL,
  BG_COLOR_OPTIONS,
  CUSTOM_BOT_CFG_TYPES,
  ASSESSMENT_SUMMARY_MODEL,
  FAQ_ITEMS,
  IDS_ACCEPTED_FILE_TYPES,
  IDS_SUPPORTED_IMAGE_TYPES,
  IDS_EXTERNAL_DELIVERY_MODELS,
};

export { IdsModels };
