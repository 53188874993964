import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { dispatch } from 'use-bus';

import { ConfirmDialog } from 'components/common/ConfirmDialog/ConfirmDialog';
import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';
import './JobActions.scss';
import { RootHooks } from 'helpers/RootHooks';
import { UserUtils } from 'helpers/UserUtils';

import { BotConstants } from '../../../constants/BotConstants';
import { EventTypeConstants } from '../../../constants/EventTypeConstants';
import { PlatformConstants } from '../../../constants/PlatformConstants';
import { IdsConstants } from './IdsConstants';
import { IdsUtils } from './IdsUtils';
import { ImgBotEngineDownloadModal } from './ImgBotEngineDownloadModal';
import { ImgBotEngineModalConfigDetails } from './ImgBotEngineModalConfigDetails';
import { ImgBotEngineModalTrigger } from './ImgBotEngineModalTrigger';

/**
 * List of buttons of actions that can be currently performed on an IDS job.
 *
 * @param {object} jobRow Image datajob, containing process_job and decorated with handlers
 * @param {boolean} isLargeBtn If true, renders large buttons
 *
 * @return render
 */
const JobActions = ({ jobRow, isLargeBtn }) => {
  // Extract decorated items
  const { onRunAssessment, onRunEnhancement, goToDetails, onCancelJob, onQualityRequest } = jobRow;

  const jobId = jobRow?.process_job?.id ?? null;
  const allowedActions = IdsUtils.getAllowedActions(jobRow?.process_job);
  const phase = IdsUtils.getPhase(jobRow?.process_job);
  const status = IdsUtils.getStatus(jobRow?.process_job);
  const { activeUser } = RootHooks.useActiveUser();

  const onModalStateChanged = (isOpen) => {
    dispatch({
      type: EventTypeConstants.IDS_MODAL_STATE_CHANGED,
      state: isOpen ? 'open' : 'closed',
    });
  };

  return (
    <div className="ids-job-action-btns">
      <ModalPanel
        header={
          <>
            <div className="ids-job-action-btns__header">Begin Initial Assessment</div>
            <div className="ids-job-action-btns__subheader">
              Run# <strong>{jobId}</strong>
            </div>
          </>
        }
        body={({ setIsVisible }) => (
          <ImgBotEngineModalTrigger
            imgJob={jobRow}
            setIsVisible={setIsVisible}
            isLoading={jobRow?.isLoading}
          />
        )}
        className="img-bot-modal-panel"
        centered
        onOpen={() => onModalStateChanged(true)}
        onClose={() => onModalStateChanged(false)}
      >
        {({ setIsVisible }) => (
          <>
            <button
              type="button"
              className={classNames('btn', 'btn-primary', 'btn-assess', { 'btn-lg': isLargeBtn })}
              onClick={() => setIsVisible(true)}
              disabled={
                jobRow?.isLoading ||
                typeof onRunAssessment !== 'function' ||
                !allowedActions?.includes(IdsConstants.ACTIONS.INITIAL_ASSESSMENT)
              }
              title="Assess"
            >
              <FontAwesomeIcon icon={['far', 'analytics']} /> <span className="label">Assess</span>
            </button>
          </>
        )}
      </ModalPanel>
      <ModalPanel
        header={
          <>
            <div className="ids-job-action-btns__header">Begin Enhancement</div>
            <div className="ids-job-action-btns__subheader">
              Run# <strong>{jobId}</strong>
            </div>
          </>
        }
        body={({ setIsVisible }) => (
          <ImgBotEngineModalTrigger
            imgJob={jobRow}
            setIsVisible={setIsVisible}
            isLoading={jobRow?.isLoading}
            isEnhancementOnly
          />
        )}
        className="img-bot-modal-panel"
        centered
        onOpen={() => onModalStateChanged(true)}
        onClose={() => onModalStateChanged(false)}
      >
        {({ setIsVisible }) => (
          <>
            <button
              type="button"
              className={classNames('btn', 'btn-primary', 'btn-enhance', { 'btn-lg': isLargeBtn })}
              onClick={() => setIsVisible(true)}
              disabled={
                jobRow?.isLoading ||
                typeof onRunEnhancement !== 'function' ||
                !allowedActions?.includes(IdsConstants.ACTIONS.ENHANCEMENT)
              }
              title="Enhance"
            >
              <FontAwesomeIcon icon={['far', 'flask-potion']} />
              <span className="label">Enhance</span>
            </button>
          </>
        )}
      </ModalPanel>

      <ConfirmDialog
        onConfirm={() => onCancelJob({ jobId })}
        headerContent="Cancel this IDS job?"
        bodyContent="Are you sure you want to cancel this IDS job? All files will be scrapped."
      >
        {({ onClick }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-cancel', { 'btn-lg': isLargeBtn })}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            disabled={
              jobRow?.isLoading ||
              typeof onCancelJob !== 'function' ||
              !allowedActions?.includes(IdsConstants.ACTIONS.CANCEL)
            }
            title="Cancel"
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} /> <span className="label">Cancel</span>
          </button>
        )}
      </ConfirmDialog>

      <ModalPanel
        header="Job Files"
        body={<ImgBotEngineDownloadModal className="icon" imgJob={jobRow} />}
        className="img-bot-download-output-modal-panel"
        centered
        onOpen={() => onModalStateChanged(true)}
        onClose={() => onModalStateChanged(false)}
      >
        {({ setIsVisible }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-files', { 'btn-lg': isLargeBtn })}
            onClick={() => setIsVisible(true)}
            disabled={jobRow?.isLoading}
            title="Files"
          >
            <FontAwesomeIcon icon={['far', 'copy']} /> <span className="label">Files</span>
          </button>
        )}
      </ModalPanel>

      <ModalPanel
        header="Job Configuration"
        className="img-bot-modal-panel"
        body={
          <ImgBotEngineModalConfigDetails
            processJob={jobRow?.process_job}
            isAssessmentOnly={
              phase === IdsConstants.PHASES.ids_initial_assessment.key &&
              !jobRow?.process_job?.config_data?.miscellaneous?.e2e_enabled
            }
          />
        }
      >
        {({ setIsVisible }) => (
          <button
            type="button"
            className={classNames('btn', 'btn-primary', 'btn-config', { 'btn-lg': isLargeBtn })}
            onClick={() => setIsVisible(true)}
            disabled={
              jobRow?.isLoading ||
              (phase === IdsConstants.PHASES.ids_extraction.key &&
                status.status === BotConstants.BOT_STATUSES.NOT_RUN) ||
              jobRow?.process_job?.config_data === null ||
              jobRow?.process_job?.config_data === undefined
            }
            title="Configuration"
          >
            <FontAwesomeIcon icon={['far', 'cogs']} /> <span className="label">Configuration</span>
          </button>
        )}
      </ModalPanel>

      {UserUtils.hasUserFeatureAccess(
        activeUser,
        PlatformConstants.PLATFORM_FEATURES.IDS_QUALITY_CHECKS
      ) && (
        <ConfirmDialog
          onConfirm={() => onQualityRequest({ jobId })}
          headerContent="Request Quality Check?"
          bodyContent="You are about to submit a request for running Quality Check on this Job. Do you want to proceed?"
        >
          {({ onClick }) => (
            <button
              type="button"
              className={classNames('btn', 'btn-primary', 'btn-qc', { 'btn-lg': isLargeBtn })}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              disabled={
                jobRow?.isLoading ||
                typeof onQualityRequest !== 'function' ||
                !allowedActions?.includes(IdsConstants.ACTIONS.QUALITY_CHECK)
              }
              title="Quality Check"
            >
              <FontAwesomeIcon icon={['far', 'eye']} /> <span className="label">Request QC</span>
            </button>
          )}
        </ConfirmDialog>
      )}
      <button
        type="button"
        className={classNames('btn', 'btn-primary', 'btn-details', { 'btn-lg': isLargeBtn })}
        onClick={() => goToDetails({ jobId })}
        disabled={
          jobRow?.isLoading ||
          typeof goToDetails !== 'function' ||
          !allowedActions?.includes(IdsConstants.ACTIONS.DETAILS)
        }
        title="Details"
      >
        <FontAwesomeIcon icon={['far', 'external-link']} /> <span className="label">Details</span>
      </button>
    </div>
  );
};

export { JobActions };
