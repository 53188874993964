import './ModalPanelWButton.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ModalPanel = ({
  onOpen,
  onClose,
  header,
  body,
  footer,
  className,
  centered = true,
  children,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const _setIsVisible = (state) => {
    setIsVisible(state);
    if (state) {
      typeof onOpen === 'function' && onOpen();
    } else {
      typeof onClose === 'function' && onClose();
    }
  };

  const renderPart = (part) => {
    if (part) {
      if (typeof part === 'function') {
        return part({ setIsVisible: _setIsVisible });
      }
      return part;
    }

    return null;
  };

  return (
    <>
      {renderPart(children)}
      <Modal
        show={isVisible}
        onHide={() => _setIsVisible(false)}
        className={classNames('modal-panel', className)}
        centered={centered}
      >
        <Modal.Header closeButton>
          {header && <Modal.Title>{renderPart(header)}</Modal.Title>}
        </Modal.Header>
        <Modal.Body className="modal-panel-body-text">{body && renderPart(body)}</Modal.Body>
        {footer && <Modal.Footer>{renderPart(footer)}</Modal.Footer>}
      </Modal>
    </>
  );
};

ModalPanel.defaultProps = {
  onOpen: () => null,
  onClose: () => null,
  header: null,
  centered: false,
  body: null,
  footer: null,
  className: null,
};

ModalPanel.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  header: PropTypes.any,
  centered: PropTypes.bool,
  body: PropTypes.any,
  footer: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export { ModalPanel };
