// Output: June 22, 2021
export const FULL_DATE = 'MMMM DD, YYYY';

// Output: 06/22
export const WEEK_DATE = 'MM/DD';

// Output: 06/22/2021
export const MIN_DATE = 'MM/DD/YYYY';

// Output: 06/22/21
export const MIN_YEAR_DATE = 'MM/DD/YY';

// Outut: 6:30 AM
export const TIME = 'hh:mm A';

// Output: 2021-06-22
export const DATE_8601 = 'YYYY-MM-DD';

// Output: 1:01:01 pm -> 13:01:01
export const TIME_8601 = 'HH:mm:ss';
