import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { HeaderUtils } from './private/HeaderUtils';

const HeaderSelfService = ({ getRootPathItem, location }) => {
  const rootPathItem = getRootPathItem();

  return (
    <>
      <li
        className={HeaderUtils.getClassName(
          rootPathItem,
          'data-lakehouse-dashboard',
          getRootPathItem() === ''
        )}
      >
        <Link to="/">
          <span className="icon">
            <FontAwesomeIcon icon={['fas', 'home']} />
          </span>
          <span className="label">Home</span>
        </Link>
      </li>
      <li className={HeaderUtils.getClassName(rootPathItem, 'image-databot-suite')}>
        <Link to="/image-databot-suite">
          <span className="icon">
            <FontAwesomeIcon icon={['fa', 'images']} />
          </span>
          <span className="label">IMAGE DATABOT SUITE</span>
        </Link>
      </li>
      {/*<li className={HeaderUtils.getClassName(rootPathItem, 'content-databot-suite')}>*/}
      {/*  <Link to="/content-databot-suite">*/}
      {/*    <span className="icon">*/}
      {/*      <FontAwesomeIcon icon={['fa', 'file-alt']} />*/}
      {/*    </span>*/}
      {/*    <span className="label">CONTENT DATABOT SUITE</span>*/}
      {/*  </Link>*/}
      {/*</li>*/}
      <li
        className={HeaderUtils.getClassName(
          rootPathItem,
          'ids-configs',
          location.pathname === '/image-databot-suite/configs'
        )}
      >
        <Link to="/image-databot-suite/configs">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'cogs']} />
          </span>
          <span className="label">Configurations</span>
        </Link>
      </li>
      <li
        className={HeaderUtils.getClassName(
          rootPathItem,
          'about',
          location.pathname === '/image-databot-suite/help'
        )}
      >
        <Link to="/image-databot-suite/help">
          <span className="icon">
            <FontAwesomeIcon icon={['far', 'info-circle']} />
          </span>
          <span className="label">Help</span>
        </Link>
      </li>
    </>
  );
};

export { HeaderSelfService };
