import React, { useEffect, useState } from 'react';

import { StyledMultiselect } from '../../../../common/StyledMultiselect/StyledMultiselect';
import './MappingComponent.scss';

/**
 * Component to map a list of targets to a list of choices
 *
 * @param targets List of targets
 * @param choices List of choices
 * @param onSetMapping Callback to set the mapping
 * @param disabled If true, the mapping cannot be changed
 * @param targetOptions Object with options for each target
 * @returns {JSX.Element} Component
 */
const MappingComponent = ({ targets, choices, onSetMapping, disabled, targetOptions = {} }) => {
  const [mapping, setMapping] = useState(undefined);

  // Reset mapping if choices are set to undefined
  useEffect(() => {
    if (!choices) {
      setMapping(undefined);
    }
  }, [choices]);

  // Initialize mapping with direct matches
  useEffect(() => {
    if (choices && choices.length && !mapping) {
      const initialMapping = {};

      targets.forEach((target) => {
        const directMatch = choices?.find(
          (choice) => choice.value.toString().toLowerCase() === target.toString().toLowerCase()
        );
        if (directMatch) {
          if (targetOptions?.[target]?.multi) {
            initialMapping[target] = [directMatch.value];
          } else {
            initialMapping[target] = directMatch.value;
          }
        }
      });

      setMapping(initialMapping);
      onSetMapping(initialMapping);
    }
  }, [targetOptions, targets, mapping, choices, onSetMapping]); // Run effect when choices change

  const handleChange = (target, selectedOption) => {
    const newMapping = { ...mapping };

    if (selectedOption) {
      if (targetOptions?.[target]?.multi) {
        newMapping[target] = [...selectedOption.map((option) => option.value)];
      } else {
        newMapping[target] = selectedOption.value;
      }
    } else {
      delete newMapping[target];
    }

    setMapping(newMapping);
    onSetMapping(newMapping);
  };

  const getAvailableChoices = () => {
    const mappedValues = [];
    Object.values(mapping || {})?.forEach((value) => {
      if (Array.isArray(value)) {
        mappedValues.push(...value);
      } else {
        mappedValues.push(value);
      }
    });

    return choices?.filter((choice) => !mappedValues.includes(choice.value));
  };

  const handlerValues = (target) => {
    let values = null;
    if (targetOptions?.[target]?.multi) {
      values = choices?.filter((choice) => mapping?.[target]?.includes(choice.value));
    } else {
      values = mapping?.[target]
        ? choices?.find((choice) => choice.value === mapping?.[target])
        : null;
    }
    return values;
  };

  return (
    <>
      <table className="header-mapping-table">
        <tbody>
          {targets.map((target) => (
            <tr className="header-mapping-row" key={target}>
              <td>
                {target}
                {!targetOptions?.[target]?.optional ? (
                  <>
                    <sup>*</sup>
                  </>
                ) : (
                  ''
                )}
              </td>
              <td>
                <StyledMultiselect
                  defaultValue={targetOptions?.[target]?.multi ? [] : null}
                  values={handlerValues(target)}
                  options={getAvailableChoices()}
                  setOnChange={(selectedOption) => handleChange(target, selectedOption)}
                  isClearable
                  isMulti={!!targetOptions?.[target]?.multi}
                  closeMenuOnSelect={!targetOptions?.[target]?.multi}
                  isSearchable
                  canReset
                  isDisabled={disabled}
                  placeholder="Select a column"
                  isInvalid={
                    !disabled &&
                    mapping?.[target] === undefined &&
                    !targetOptions?.[target]?.optional
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export { MappingComponent };
