/*
Optimize bundle size by importing only the used icons
from Font Awesome into a library.
*/

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFolderUpload as faFolderUploadDuotone,
  faStopwatch as faStopwatchDuotone,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBan as faBanLight,
  faBell as faBellLight,
  faCheck as faCheckLight,
  faCircle as faCircleLight,
  faComments as faCommentsLight,
  faEdit as faEditLight,
  faFrown as faFrownLight,
  faMeh as faMehLight,
  faPaperPlane as faPaperPlaneLight,
  faPaperclip as faPaperclipLight,
  faPause as faPauseLight,
  faSmile as faSmileLight,
  faUserFriends as faUserFriendsLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAnalytics,
  faArrowFromLeft,
  faArrowToBottom,
  faAddressCard,
  faAt,
  faBan,
  faBell,
  faBold,
  faBolt,
  faBorderAll,
  faBoxFull,
  faBoxesAlt,
  faCalendarCheck,
  faCalendarPlus,
  faCaretRight,
  faCaretUp,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faCheck,
  faCheckCircle,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
  faCloudDownload,
  faCloudUpload,
  faCode,
  faComments,
  faCopy,
  faCubes,
  faDownload,
  faEdit,
  faEllipsisH,
  faEquals,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileChartLine,
  faFileDownload,
  faFilePlus,
  faFileSearch,
  faFileUpload,
  faFilter,
  faFlaskPotion,
  faFrown,
  faH1,
  faH2,
  faHandHoldingWater,
  faHome,
  faInfo,
  faInfoCircle,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowAltRight,
  faMeh,
  faMinus,
  faPaperPlane,
  faPaperclip,
  faPause,
  faPlayCircle,
  faPlus,
  faPlusHexagon,
  faQuestionCircle,
  faQuoteRight,
  faSave,
  faSearch,
  faServer,
  faSignOut,
  faSignal,
  faSmile,
  faStar,
  faTachometer,
  faTasks,
  faThList,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnderline,
  faUndo,
  faUnlink,
  faUserFriends,
  faWarehouseAlt,
  faCogs,
  faExclamationCircle,
  faEllipsisV,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faFileAlt,
  faImages,
  faSortAlt,
  faTimes as faTimesSolid,
  faTimesCircle as faTimesCircleSolid,
  faArrowAltToTop as faArrowAltToTopSolid,
  faArrowsAltV as faArrowsAltVSolid,
  faAngleDoubleLeft as faAngleDoubleLeftSolid,
  faAngleDoubleRight as faAngleDoubleRightSolid,
  faBan as faBanSolid,
  faBell as faBellSolid,
  faBolt as faBoltSolid,
  faBoxOpen as faBoxOpenSolid,
  faCalendarAlt as faCalendarAltSolid,
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
  faCaretUp as faCaretUpSolid,
  faChartPie as faChartPieSolid,
  faCheck as faCheckSolid,
  faCheckCircle as faCheckCircleSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronDoubleUp as faChevronDoubleUpSolid,
  faChevronDoubleDown as faChevronDoubleDownSolid,
  faCircle as faCircleSolid,
  faComments as faCommentsSolid,
  faCommentsAlt as faCommentsAltSolid,
  faDownload as faDownloadSolid,
  faEdit as faEditSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faForward as faForwardSolid,
  faFileEdit as faFileEditSolid,
  faFrown as faFrownSolid,
  faHome as faHomeSolid,
  faInfo as faInfoSolid,
  faInfoCircle as faInfoCircleSolid,
  faLightbulb as faLightbulbSolid,
  faLock as faLockSolid,
  faLongArrowDown as faLongArrowDownSolid,
  faLongArrowUp as faLongArrowUpSolid,
  faBullhorn as faBullhornSolid,
  faMeh as faMehSolid,
  faPaperPlane as faPaperPlaneSolid,
  faPen as faPenSolid,
  faPencil as faPencilSolid,
  faPaperclip as faPaperclipSolid,
  faPlay as faPlaySolid,
  faPlus as faPlusSolid,
  faRedoAlt as faRedoAltSolid,
  faStar as faStarSolid,
  faSearchPlus as faSearchPlusSolid,
  faPause as faPauseSolid,
  faSmile as faSmileSolid,
  faSort as faSortSolid,
  faSortDown as faSortDownSolid,
  faSortUp as faSortUpSolid,
  faTrash as faTrashSolid,
  faUserFriends as faUserFriendsSolid,
  faWarehouseAlt as faWarehouseAltSolid,
  faEllipsisV as faEllipsisVSolid,
  faHourglassStart as faHourglassStartSolid,
  faCog as faCogSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faEye as faEyeSolid,
  faNetworkWired as faNetworkWiredSolid,
} from '@fortawesome/pro-solid-svg-icons';

library.add([
  faFileAlt,
  faImages,
  faAnalytics,
  faArrowToBottom,
  faArrowFromLeft,
  faAddressCard,
  faArrowAltToTopSolid,
  faAngleDoubleLeftSolid,
  faAngleDoubleRightSolid,
  faArrowsAltVSolid,
  faAt,
  faBan,
  faBanLight,
  faBanSolid,
  faBell,
  faBellLight,
  faBellSolid,
  faSortAlt,
  faBold,
  faBolt,
  faBoltSolid,
  faBorderAll,
  faBoxFull,
  faBoxesAlt,
  faBoxOpenSolid,
  faCalendarPlus,
  faCalendarCheck,
  faCalendarAltSolid,
  faCaretDownSolid,
  faCaretRight,
  faCaretRightSolid,
  faCaretUp,
  faCaretUpSolid,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartPieSolid,
  faCheck,
  faCheckLight,
  faCheckSolid,
  faCheckCircle,
  faCheckCircleSolid,
  faChevronDownSolid,
  faChevronUpSolid,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUpSolid,
  faChevronDoubleDownSolid,
  faChevronLeft,
  faChevronLeftSolid,
  faChevronRight,
  faChevronRightSolid,
  faCircleLight,
  faCircleSolid,
  faCloudDownload,
  faCloudUpload,
  faPlusSolid,
  faCode,
  faComments,
  faCommentsAltSolid,
  faCommentsLight,
  faCommentsSolid,
  faCopy,
  faCubes,
  faDownload,
  faDownloadSolid,
  faEdit,
  faEditLight,
  faEditSolid,
  faEllipsisH,
  faEquals,
  faExclamationTriangleSolid,
  faExternalLink,
  faEye,
  faEyeSlash,
  faForwardSolid,
  faFile,
  faFileChartLine,
  faFileDownload,
  faFileEditSolid,
  faFilePlus,
  faFileSearch,
  faFileUpload,
  faFilter,
  faFlaskPotion,
  faStopwatchDuotone,
  faFolderUploadDuotone,
  faFrown,
  faFrownLight,
  faFrownSolid,
  faH1,
  faH2,
  faHandHoldingWater,
  faHome,
  faHomeSolid,
  faInfo,
  faInfoCircle,
  faInfoCircleSolid,
  faInfoSolid,
  faItalic,
  faLightbulbSolid,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLockSolid,
  faLongArrowDown,
  faLongArrowDownSolid,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowUpSolid,
  faLongArrowAltRight,
  faBullhornSolid,
  faMeh,
  faMehLight,
  faMehSolid,
  faMinus,
  faPaperPlane,
  faPaperPlaneLight,
  faPaperPlaneSolid,
  faPaperclip,
  faPaperclipLight,
  faPaperclipSolid,
  faPause,
  faPauseLight,
  faPauseSolid,
  faPenSolid,
  faPencilSolid,
  faPlayCircle,
  faPlaySolid,
  faPlus,
  faPlusHexagon,
  faQuestionCircle,
  faQuoteRight,
  faRedoAltSolid,
  faSave,
  faSearch,
  faSearchPlusSolid,
  faServer,
  faSignOut,
  faSignal,
  faSmile,
  faSmileLight,
  faSmileSolid,
  faSortSolid,
  faSortDownSolid,
  faSortUpSolid,
  faStar,
  faStarSolid,
  faTachometer,
  faTasks,
  faThList,
  faTimes,
  faTimesSolid,
  faTimesCircle,
  faTimesCircleSolid,
  faTrashAlt,
  faTrashSolid,
  faUnderline,
  faUndo,
  faUnlink,
  faUserFriends,
  faUserFriendsLight,
  faUserFriendsSolid,
  faWarehouseAlt,
  faWarehouseAltSolid,
  faCogs,
  faExclamationCircle,
  faEllipsisV,
  faEllipsisVSolid,
  faHourglassStartSolid,
  faCogSolid,
  faExclamationCircleSolid,
  faEyeSolid,
  faNetworkWiredSolid,
]);
