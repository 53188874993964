const PHASES = {
  ids_preprocessing: { key: 'ids_preprocessing', label: 'Preprocessing' },
  ids_extraction: { key: 'ids_extraction', label: 'Image Extraction' },
  ids_initial_review: { key: 'ids_initial_review', label: 'Internal Review (Initial)' },
  ids_initial_assessment: { key: 'ids_initial_assessment', label: 'Initial Assessment' },
  ids_enhancement: { key: 'ids_enhancement', label: 'Enhancement' },
  ids_final_assessment: { key: 'ids_final_assessment', label: 'Final Assessment' },
  ids_final_review: { key: 'ids_final_review', label: 'Internal Review (Final)' },
  ids_quality_check: { key: 'ids_quality_check', label: 'Quality Check' },
};

const ACTIONS = {
  INITIAL_ASSESSMENT: 'INITIAL_ASSESSMENT',
  ENHANCEMENT: 'ENHANCEMENT',
  DETAILS: 'DETAILS',
  CANCEL: 'CANCEL',
  QUALITY_CHECK: 'QUALITY_CHECK',
};

const ERROR_TYPE_MESSAGES = {
  generic: 'Unknown Error',
  db_generic: 'Unknown Database Error',
  db_connection: 'Failed Database Connection Error',
  db_integrity: 'Database Integrity Violation Error',
  db_execution: 'Database Statement Execution Error',
  db_timeout: 'Database Timeout Error',
  https_generic: 'Unknown HTTPS Error',
  api_unavailable: 'API Unavailable Error',
  api_authentication: 'API Authentication Error',
  api_request_failed: 'Failed API Request Error',
  vs_infected_file:
    'We cannot process your file, as it appears to be a potential security risk. Please try a different file or contact support for assistance.',
};

const ERROR_LOCATION_MESSAGES = {
  virus_scan: 'Virus Scan',
  startup: 'Initialization Phase',
  shutdown: 'Finalization Phase',
  generic: 'Unknown',
  process_generic: 'Image Processing Phase',
  process_db_pull: 'DataBase Input Query',
  process_init: 'Processor Worker Initialization Phase',
  process_done: 'Procesor Worker Shutdown Phase',
  process_download: 'Processor Worker Item Download',
  process_upload: 'Processor Worker Item Upload',
  export_generic: 'Processing Result Export Phase',
  aggregation_generic: 'Aggregation Phase',
  aggregation_report: 'Aggregation Report Generation Phase',
  aggregation_output: 'Aggregation Output Zip Generation Phase',
  aggregation_init: 'Aggregation Initialization Phase',
  aggregation_result_processing: 'Aggregation Result Processing Phase',
  aggregation_result_export: 'Aggregation Report Export Phase',
  ids_output_zip_creation: 'Output Zip Creation Phase',
};

const FORM_ERROR_MESSAGES = {
  invalid_config_form:
    'Your config is invalid. Please apply the necessary corrections before proceeding.',
  jpeg_transparent:
    'JPEG format does not support a transparent background. Please select a PNG format or a White background.',
  tiff_transparent:
    'Saving images with transparent background in TIFF format usually results in a file 7x time larger than its PNG counterpart. We recommend using either PNG or WEBP.',
  jpeg_no_bg:
    'Saving as JPEG without turning on background removal/replacement may result in incorrect handling of images with transparent backgrounds. We recommend activating this feature for optimal image quality.',
  valid_path: 'Invalid folder name or path.',
  valid_host: 'Invalid address or IPv4',
  valid_connection: 'Connection to the FTP server failed.',
  external_delivery_destination_not_selected: 'Please select an external delivery destination.',
  external_delivery_connection_test_not_run: 'Please test the external delivery connection.',
  external_delivery_connection_test_failed:
    'Failed to connect to the external delivery destination.',
};

const IDS_CONFIG_TYPES = {
  ids_initial_assessment: {
    value: 'assessment',
    slug: 'ids-initial-assessment',
    label: 'Assessment',
  },
  ids_enhancement: {
    value: 'enhancement',
    slug: 'ids-enhancement',
    label: 'Enhancement',
  },
};

const SELF_SERVICE_ACCOUNT_TIERS = {
  free_trial: {
    key: 'free_trial',
    label: 'Free Trial',
  },
  paid: {
    key: 'paid',
    label: 'Paid',
  },
};

const IDS_EXTERNAL_DELIVERY_DESTINATIONS = [{ value: 'ftp', label: 'FTP' }];

const IdsConstants = {
  PHASES,
  ACTIONS,
  ERROR_TYPE_MESSAGES,
  ERROR_LOCATION_MESSAGES,
  FORM_ERROR_MESSAGES,
  IDS_CONFIG_TYPES,
  SELF_SERVICE_ACCOUNT_TIERS,
  IDS_EXTERNAL_DELIVERY_DESTINATIONS,
};

export { IdsConstants };
