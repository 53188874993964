import React, { useEffect } from 'react';

import { isHexWithTransparency } from '../../../../helpers/Utils';
import { CustomBotConfigForm } from '../CustomBotConfigForm';
import { CustomBotConfigUtils } from '../CustomBotConfigUtils';
import { IdsConstants } from '../IdsConstants';
import { IdsModels } from '../IdsModels';

const IdsEnhancementConfigForm = ({
  config,
  setCustomConfig,
  isLoading,
  setValidationResult,
  isAnonymous = false,
}) => {
  useEffect(() => {
    let hasError = CustomBotConfigUtils.containsError(config);
    let message = hasError ? IdsConstants.FORM_ERROR_MESSAGES.invalid_config_form : undefined;
    if (
      config?.format_conversion?.is_enabled &&
      config?.bg_removal?.is_enabled &&
      isHexWithTransparency(config?.bg_removal?.value)
    ) {
      switch (config?.format_conversion?.value) {
        case 'jpeg':
          hasError = true;
          message = IdsConstants.FORM_ERROR_MESSAGES.jpeg_transparent;
          break;
        case 'tiff':
          message = IdsConstants.FORM_ERROR_MESSAGES.tiff_transparent;
          break;
        default:
          break;
      }
    }

    if (config?.format_conversion?.value === 'jpeg' && !config?.bg_removal?.is_enabled) {
      message = IdsConstants.FORM_ERROR_MESSAGES.jpeg_no_bg;
    }
    setValidationResult?.({ isValid: !hasError, message });
  }, [config, setValidationResult]);

  return (
    <section className="custom-bot-config">
      <CustomBotConfigForm
        isAnonymous={isAnonymous}
        customConfig={config}
        setCustomConfig={setCustomConfig}
        isLoading={isLoading}
        configGroups={IdsModels.CUSTOM_ENHANCEMENT_BOT_CFG_GROUPS}
        configModel={IdsModels.CUSTOM_ENHANCEMENT_BOT_CFG_MODEL}
      />
    </section>
  );
};

export { IdsEnhancementConfigForm };
