import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';

const ConfirmDialog = ({
  onConfirm,
  onReject,
  headerContent,
  bodyContent,
  className,
  children,
}) => {
  const _onConfirm = ({ setIsVisible }) => {
    setIsVisible(false);
    if (typeof onConfirm === 'function') {
      onConfirm();
    } else {
      console.warn('Missing onConfirm handler');
    }
  };

  const _onReject = ({ setIsVisible }) => {
    setIsVisible(false);
    if (typeof onReject === 'function') {
      onReject();
    }
  };

  return (
    <ModalPanel
      className={className}
      header={headerContent}
      body={bodyContent || 'Are you sure?'}
      footer={({ setIsVisible }) => (
        <>
          <Button variant="secondary" onClick={() => _onReject({ setIsVisible })}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => _onConfirm({ setIsVisible })}>
            OK
          </Button>
        </>
      )}
    >
      {({ setIsVisible }) =>
        typeof children === 'function' && children({ onClick: () => setIsVisible(true) })
      }
    </ModalPanel>
  );
};

ConfirmDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  headerContent: PropTypes.any,
  bodyContent: PropTypes.any,
  children: PropTypes.func.isRequired,
};

export { ConfirmDialog };
