import React, { useCallback, useState, useEffect } from 'react';

import { BlockBlobClient } from '@azure/storage-blob';
import { Modal } from 'react-bootstrap';

import * as ApiCalls from 'api/ApiCalls';
import { FileUpload } from 'components/common/FileUpload/FileUpload';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { getIconWithPopover } from '../../../helpers/Utils';
import { IdsModels } from './IdsModels';
import { ImgBotEngineModalTrigger } from './ImgBotEngineModalTrigger';
import './NewJobSection.scss';

/**
 * Upload panel for creating a new IDS job
 * @param {function} onRunAssessment Handler for starting an initial assessment
 * @param {function} onJobCreated Callback handler for successful start.
 * @param {function} setIsNewJobInProgress setter for flag
 * // TODO: This connection should be refactored in a more elegant way
 *
 * @return render
 */
const NewJobSection = ({ onRunAssessment, onJobCreated, setIsNewJobInProgress }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB
  const isMounted = useIsMounted();

  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFileName, setUpLoadingFileName] = useState(false);

  // Newly created IDS job - process_job
  const [imgJob, setImgJob] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const reset = () => {
    setIsUploadComplete(false);
    setUploadProgress(0);
    setIsUploading(false);
  };

  useEffect(() => {
    if (typeof setIsNewJobInProgress === 'function') {
      setIsNewJobInProgress(isUploading || isUploadComplete);
    }
  }, [isUploading, isUploadComplete, setIsNewJobInProgress]);

  const showToast = useCallback(
    (message, type = 'error') => {
      if (isMounted.current) {
        toast[type](message);
      }
    },
    [isMounted]
  );

  const onUploadError = useCallback(
    (err) => {
      if (!err?.response?.data?.message) {
        let toastMessage = 'There was an error creating the job. Please try again.';
        if (err?.code === 'ERR_NETWORK') {
          toastMessage = 'Network error encountered while creating job. Please retry.';
        } else if (err?.response?.status === 400) {
          toastMessage = err?.response?.data?.message;
        }

        showToast(toastMessage);
      }
      reset();
    },
    [showToast]
  );

  const getDefaultConfigData = useCallback(
    (data, defaultConfigDataType) => {
      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.GET,
        urlPath: `/bots/process-job/default-config/${defaultConfigDataType}`,
        onSuccess: (res) => {
          setImgJob({ ...data, config_data: res.data });
        },
        onError: (err) => {
          onUploadError(err);
        },
      });
    },
    [onUploadError, setImgJob]
  );

  const doCreateIdsRun = useCallback(
    (files, blobName) => {
      const defaultConfigDataType = 'ids';

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: `/bots/process-job`,
        data: {
          file_name: files[0].name,
          blob_name: blobName,
        },
        onSuccess: (res) => {
          showToast(
            `Successfully uploaded "${res.data?.additional_data[0]?.file_name}"`,
            'success'
          );

          setIsUploadComplete(true);
          if (!res?.data?.config_data) {
            getDefaultConfigData(res.data, defaultConfigDataType);
          } else {
            setImgJob(res.data);
          }

          onJobCreated(res.data);
          setIsVisible(true);
        },
        onError: (err) => {
          onUploadError(err);
        },
        onEnd: () => {
          setIsUploadComplete(false);
          setIsUploading(false);
          setUploadProgress('0');
          setUpLoadingFileName(null);
        },
      });
    },
    [getDefaultConfigData, showToast, onJobCreated, onUploadError, setImgJob]
  );

  const doUploadFile = useCallback(
    (files, { sasUrl, blobName }) => {
      // Upload file

      setUpLoadingFileName(files[0].name);
      const blobClient = new BlockBlobClient(sasUrl);

      blobClient
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 4,
          blobHTTPHeaders: {
            blobContentType: files[0].type,
          },
          onProgress: (progress) => {
            const percentage = ((progress.loadedBytes / files[0].size) * 100).toFixed(0);
            if (percentage >= 0) setUploadProgress(percentage);
          },
        })
        .then(() => {
          doCreateIdsRun(files, blobName);
        })
        .catch((err) => {
          onUploadError(err);
        });
    },
    [doCreateIdsRun, onUploadError]
  );

  const beginCreateIdsRun = useCallback(
    (files) => {
      setIsUploading(true);
      setUploadProgress('0');
      setIsUploadComplete(false);

      const reqBody = {
        file_name: files[0].name,
        file_size: files[0].size,
      };

      ApiCalls.doCall({
        method: ApiCalls.HTTP_METHODS.POST,
        urlPath: '/bots/process-job/prepare',
        data: reqBody,
        onSuccess: (res) => {
          if (!res.data.sas_url) {
            showToast('There was an error creating the job. Please try again.');
            reset();
          } else {
            doUploadFile(files, { blobName: res.data.blob_name, sasUrl: res.data.sas_url });
          }
        },
        onError: (err) => {
          onUploadError(err);
        },
      });
    },
    [showToast, doUploadFile, onUploadError]
  );

  const onSelectFiles = (files) => {
    // TODO: Different cases:
    // - If zip/csv/xls/xlsx file, allow only 1
    // - If multiple img files, allow only img files, max 10
    // - Check file(s) single and cumulative size

    // TODO: Initial case - single zip, csv, xls/x

    if (!(files?.length === 1 && files[0]?.name)) {
      toast.error('You have to select a single file');
      return;
    }

    let isValidTypes = true;

    files.forEach((item) => {
      if (!/\.(xls|xlsx|csv|zip)$/i.test(item.name)) {
        toast.error(
          `File "${item.name}" is of invalid type. Allowed types are XLS, XLSX, CSV or a ZIP.`
        );
        isValidTypes = false;
      }
      if (item.size > MAX_FILE_SIZE) {
        toast.error(`File "${item.name}" is too large. Maximum allowed size is 2GB.`);
        isValidTypes = false;
      }
    });

    if (isValidTypes && files?.length) {
      beginCreateIdsRun(files);
    }
  };

  const handleOnHide = () => {
    setIsVisible(false);
    setImgJob(null);
  };

  return (
    <div className="new-job-section">
      <>
        <div className="uploader-panel">
          <FileUpload
            onDrop={onSelectFiles}
            maxFileCount={1}
            isFileLoading={isUploading}
            uploadProgress={uploadProgress}
            isUploadComplete={isUploadComplete}
            setIsUploadComplete={setIsUploadComplete}
            uploadingFileName={uploadingFileName}
            onSuccessMessage="File successfully added!"
            infoText={
              <>
                <div className="supported-formats-wrapper">
                  <div className="formats-row-item">
                    <div className="af-title">
                      File Formats{' '}
                      {getIconWithPopover({
                        iconProp: ['far', 'question-circle'],
                        title: 'Upload File Formats',
                        content: (
                          <>
                            The following file formats are supported for <strong>upload</strong>. If
                            you require support for a different format, please contact us.
                          </>
                        ),
                      })}
                    </div>
                    <div className="file-upload-allowed-files">
                      {IdsModels.IDS_ACCEPTED_FILE_TYPES.map((item, index) => (
                        <div key={index} className="file-item">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <span className="separator" />
                  <div className="formats-row-item">
                    <div className="af-title">
                      Image formats{' '}
                      {getIconWithPopover({
                        iconProp: ['far', 'question-circle'],
                        title: 'Processing Image formats',
                        content: (
                          <>
                            The following image formats are supported for{' '}
                            <strong>processing</strong>. If you require support for a different
                            format, please contact us.
                          </>
                        ),
                      })}
                    </div>
                    <div className="file-upload-allowed-files">
                      {IdsModels.IDS_SUPPORTED_IMAGE_TYPES.map((item, index) => (
                        <div key={index} className="file-item">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <u>Maximum</u>: <strong>10k images</strong> or <strong>2GB</strong>.
                </div>
              </>
            }
          />
        </div>
      </>
      {imgJob && (
        <Modal
          show={isVisible}
          onHide={() => handleOnHide()}
          className="modal-panel img-bot-modal-panel"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="ids-job-action-btns__header">Begin Initial Assessment</div>
              <div className="ids-job-action-btns__subheader">
                Run# <strong>{imgJob.id}</strong>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-panel-body-text">
            <ImgBotEngineModalTrigger
              imgJob={{
                process_job: imgJob,
                onRunAssessment,
              }}
              setIsVisible={setIsVisible}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export { NewJobSection };
