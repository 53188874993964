import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isUrl from 'is-url';
import _escapeRegExp from 'lodash/escapeRegExp';
import _get from 'lodash/get';
import Moment from 'moment';
import queryString from 'query-string';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { convertNodeToElement } from 'react-html-parser';

import * as ApiCalls from 'api/ApiCalls';
import { PRODUCT_ASSET_TYPES } from 'constants/ProductConstants';
import { getAuthToken } from 'helpers/AuthTokenUtils';

import { LoadingSpinner } from '../components/common/LoadingSpinner/LoadingSpinner';

/**
 * Case-insensitive comparison for variables/constants.
 * Example usage:
 *
 * -> type = 'manufacturer'
 * -> isConstantEqual(type, 'Manufacturer')
 * -> returns true
 *
 * @param {string} value The base value to compare
 * @param {string} other The other value to compare
 * @returns boolean
 */
export const isConstantEqual = (value, other) => {
  return value.toLowerCase() === other.toLowerCase();
};

/**
 * Downloads a file from the provided URL
 *
 * @param {string} url
 * @returns void
 */
export const downloadFile = (url, filename) => {
  if (!url) return;

  const link = document.createElement('a');
  link.href = url;
  link.download = filename ?? true;
  link.style.display = 'none';
  link.target = '_blank';
  link.rel = 'noopener noreferrer';

  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  document.body.appendChild(link);
  link.dispatchEvent(event);
  document.body.removeChild(link);
};

/**
 * Downloads an Excel file returned as blob data, rather than a redirect
 *
 * @param {blob} blobData File data as a blob
 * @param {object} headers Response headers
 */
export const downloadExcelFile = (blobData, headers) => {
  const blob = new Blob([blobData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    url = window.URL.createObjectURL(blob);

  const contentDisposition = headers['content-disposition'];
  const filename = contentDisposition
    .slice(contentDisposition.indexOf('filename='))
    .replace('filename=', '');

  downloadFile(url, filename);
};

/**
 * Return root element for use in Select components auto menu placement
 *
 * @returns HTMLElement
 */
export const getRootMenuPortalTarget = () => document.getElementById('root');

// Generate Azure URL for product asset
export const getAzureUrl = (product, assetType, fileName) => {
  if (!Object.values(PRODUCT_ASSET_TYPES).includes(assetType)) {
    console.error('Invalid asset type', product, assetType);
    return null;
  }

  if (!fileName || !fileName.length) {
    console.error('Argument fileName expected, provided: ', fileName);
  }

  if (!product?.id) {
    console.debug('No product');
    return null;
  }

  const authToken = getAuthToken();
  if (!authToken?.length) {
    console.error("Couldn't get token");
    return null;
  }

  return `${ApiCalls.BASE_API_URL}/product/${
    product.id
  }/assets/?type=${assetType}&name=${encodeURIComponent(fileName)}&token=${authToken}`;
};

/**
 * Downloads a file from the provided URL
 *
 * @param {funciton} func The function to test if null or not
 * @returns bool true if function is of type function
 */
export const isFunction = (func) => {
  if (func === undefined || !func || typeof func !== 'function') {
    return false;
  }
  return true;
};

export const getTimeElapsed = (dataRequest) => {
  if (!dataRequest) {
    return null;
  }

  const inProcessAt = Moment(dataRequest.in_process_since);
  let completedAt = Moment(dataRequest.completed_at);
  if (!completedAt.isValid()) {
    completedAt = Moment();
  }

  if (inProcessAt.isValid()) {
    return Moment.duration(completedAt.diff(inProcessAt)).format('d [days]');
  }

  return null;
};

/**
 * formatBytes.
 *
 * @param {number} a - size in Bytes
 * @param {number} b - base, default 2
 * @return - string with formated Bytes
 */
export const formatBytes = (a, b = 2) => {
  if (a === 0) return '0 Bytes';
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
    ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  }`;
};

/**
 * checkFileExtAndSize.
 *
 * @param {array} e - an array containing the file to check size and extension of at index 0
 * @callback typeErrorCallback - callback for when the type is incorrect
 * @callback sizeErrorCallback - callback for when the size is too large
 * @return - false if fail, file obj if true
 */
export const checkFileExtAndSize = (e, typeErrorCallback, sizeErrorCallback) => {
  if (!e || (e && !e[0])) {
    console.warn('No file found');
    return false;
  }

  const file = e[0];

  if (/\.(exe|js|sh)$/i.test(file.name)) {
    if (typeErrorCallback) {
      typeErrorCallback(file.name);
    } else {
      console.warn('File extension not allowed');
    }
    return false;
  }
  if (file.size > 2e9) {
    if (sizeErrorCallback) {
      sizeErrorCallback(file.name);
    } else {
      console.warn('File exceeds size limit');
    }
    return false;
  }
  return file;
};

export const getTimeRemaining = (dataRequest) => {
  if (!dataRequest) {
    return 'N/A';
  }

  const momentNow = Moment();
  const momentDueDate = Moment(_get(dataRequest, 'due_date'));
  const momentRemaining = momentDueDate.diff(momentNow);

  if (momentDueDate.isValid()) {
    if (momentRemaining >= 0) {
      const remainder = Moment.duration(momentRemaining);
      const remainderDays = Math.floor(remainder.asDays());
      return `${remainderDays} day${remainderDays > 1 ? 's' : ''}`;
    }
    return '0 days';
  }

  return 'N/A';
};

export const getDefaultTableColumnDef = (dataField, text) => {
  return {
    dataField,
    classes: dataField,
    headerClasses: dataField,
    text: text || '',
    sort: true,
    formatter: (_cell, row) => _get(row, dataField, 'N/A'),
    headerFormatter: (col, _index, components) => {
      return (
        <span className="th-wrap">
          <span className="label">{col.text}</span>
          {components.sortElement ? <span className="sort">{components.sortElement}</span> : null}
          {components.filterElement ? (
            <span className="filter">{components.filterElement}</span>
          ) : null}
        </span>
      );
    },
    sortCaret: (order, column) => {
      // Slightly hacky fix for data jobs filter preserve bug
      const params = queryString.parse(window.location?.search);

      if (window.location.href.includes('data-jobs')) {
        if (params.sort_field) {
          if (params.sort_field !== column.dataField) order = null;
          else {
            if (params.sort_order) order = params.sort_order;
            else if (order === undefined) order = 'desc';
          }
        } else if (params.sort_order && column.dataField === 'id') {
          order = params.sort_order;
        }
      }

      return (
        <span className={`sort-caret${order ? ` ${order}` : ''}`}>
          <span className="icon asc">
            <FontAwesomeIcon icon={['fas', 'caret-up']} />
          </span>
          <span className="icon desc">
            <FontAwesomeIcon icon={['fas', 'caret-down']} />
          </span>
        </span>
      );
    },
  };
};

// FIXME: If a string is passed in that contains a URL, even if it is not a URL itself, it passes the validation test. This is more like a "contains URL" rather than "is URL".
// Use isUrl (is-url) instead.
export const isUrlValid = (url) => {
  if (!(url && url.length > 5)) {
    return false;
  }

  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;

  return urlRegex.test(url);
};

/**
 * Checks if the given string contains a URL
 *
 * @param {string} input
 * @returns bool
 */
export const containsUrl = (input) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return urlRegex.test(input);
};

/**
 * Replace all links in a string with anchor tags
 *
 * @param {string} input
 * @returns string
 */
export const linkify = (input) => {
  let replacedText;

  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = input.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  return replacedText;
};

/**
 * Sanitizes react-html-parser nodes when rendering text
 *
 * @param {*} node react-html-parser node
 * @param {*} index
 * @param {string} linkClasses classes to assign to the anchor node
 * @returns Transformed react element
 */
export const transformHtmlNodes = (node, index, linkClasses = null) => {
  if (node.type === 'tag') {
    if (['iframe', 'script'].includes(node.name)) {
      return null;
    }
    if (node.name === 'a') {
      let isValid = false;
      let tmpUrl = node.attribs.href;

      if (tmpUrl) {
        if (tmpUrl.startsWith('www.')) {
          tmpUrl = `http://${tmpUrl}`;
        }

        if (isUrl(tmpUrl)) {
          node.attribs.target = '_blank';
          node.attribs.rel = 'external noopener noreferrer';
          if (linkClasses) node.attribs.class = linkClasses;

          if (tmpUrl !== node.attribs.href) {
            node.attribs.href = tmpUrl;
          }

          isValid = true;
        }
      }

      if (!isValid) {
        node.name = 'span';
      }

      return convertNodeToElement(node, index, transformHtmlNodes);
    }
    if (node.name === 'span') {
      return convertNodeToElement(node, index, transformHtmlNodes);
    }
  }
};

export const isEmailValid = (email) => {
  if (!(email && email.length > 5)) {
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

  return emailRegex.test(email);
};

export const joinNameToStr = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return '';
};

export const buildTextWithUrl = (content, acceptAll, className) => {
  const checkURL = (text) => {
    const ie = navigator.userAgent.indexOf('MSIE');
    try {
      let url;
      if (ie !== -1) {
        // for all browsers besides ie11
        url = new URL(text);
      } else {
        // for ie11 because it can't use the URL function
        const regexQuery =
          '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
        url = new RegExp(regexQuery, 'i');
        return url.test(text);
      }
    } catch (_) {
      return false;
    }
    return true;
  };

  // check for urls in the comment
  if (
    content.includes('https') ||
    content.includes('http') ||
    content.includes('.com') ||
    content.includes('.org')
  ) {
    const segments = content.split(' ');
    for (let i = 0; i < segments.length; i++) {
      if (checkURL(segments[i].trim())) {
        if (!segments[i].includes('http')) {
          segments[i] = `http://${segments[i]}`;
        }
        segments[i] = (
          <a
            key={i}
            className={className}
            href={segments[i]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {segments[i]}{' '}
          </a>
        );
      } else if (
        acceptAll === true &&
        (segments[i].includes('.net') ||
          segments[i].includes('.com') ||
          segments[i].includes('.org'))
      ) {
        segments[i] = (
          <a
            key={i}
            className={className}
            href={`http://${segments[i]}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {segments[i]}{' '}
          </a>
        );
      } else {
        segments[i] += ' ';
      }
    }
    content = segments;
  }
  return <>{content}</>;
};

export const excelHeaders = (index) => {
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const i = Math.floor(index / 26);
  let final = '';
  if (i > 0) {
    final += letters[i - 1];
  }
  final += letters[index % 26];
  return final;
};

/**
 * Sorts data in alphabetical/ascending order (A-Z) based on provided key
 *
 * @param {array} data An array of objects with consistent key-value entries
 * @param {string} key The key to sort by
 * @param {boolean} reverse Whether or not to reverse the sorted list (asc/desc)
 * @returns array
 */
export const sortByKey = (data, key, reverse = false) => {
  const sorted = data?.sort((a, b) => {
    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  });

  return reverse ? sorted.reverse() : sorted;
};

/**
 * basic debounce function
 */
export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const paginate = (totalItems, currentPage = 1, pageSize = 10, maxPages = 10) => {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
};

export const Highlighted = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        )}
    </span>
  );
};

export const getFormattedIntOrString = (v, placeholder = null) => {
  let output = placeholder;

  if (![null, undefined, ''].includes(v)) {
    output = Number.isFinite(v) ? v.toLocaleString('en-US') : v;
  }

  return output;
};

// checks to see if every item in one array is in another. Will fail with duplicates.
export const equalUnsortedArrays = (arr1, arr2) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
};

// convert snake case to a group of capitalized words
export const snakeCaseToPhrase = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  const words = str.split('_');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

// update an object with the non-null keys from another
export const updateObject = (target, src) => {
  const res = {};
  Object.keys(target).forEach((k) => (res[k] = src[k] ?? target[k]));
  return res;
};

/**
 * Converts a hex string to its rgba object counterpart
 *
 * @param {string} hexStr the string to be analyzed
 *
 * @return {{r: int, g: int, b: int, a: number}} parsed rgba object
 */
export const hexToRgba = (hexStr) => {
  // Remove the `#` from the hex string
  const hexNoHash = hexStr.replace('#', '');

  // Extract the red, green, and blue values
  const r = parseInt(hexNoHash.substring(0, 2), 16);
  const g = parseInt(hexNoHash.substring(2, 4), 16);
  const b = parseInt(hexNoHash.substring(4, 6), 16);

  // Extract the alpha value (if it exists)
  let a = 255;
  if (hexNoHash.length === 8) {
    a = parseInt(hexNoHash.substring(6, 8), 16);
  }

  // Return the RGBA object
  return { r, g, b, a };
};

/**
 * Validates if a given string is a valid hex color string, incliding alpha
 *
 * @param {string} hexStr the string to be analyzed
 *
 * @return {boolean} true if the string is a valid hex string with alpha
 */
export const isHexWithAlphaValid = (hexStr) => {
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
  return hexRegex.test(hexStr);
};

const _hexPad = (value) => {
  return value.length === 1 ? `0${value}` : value;
};

/**
 * Converts a rgba object to it's hex counterpart
 *
 * @param {{r: int, g: int, b: int, a: number}} rgba the object containing the RGBA values, where the alpha is set as a floating point value between 0 and 1.0
 *
 * @return {string} the coresponding hex string with the transparency at the end if given
 */
export const rgbaToHex = (rgba) => {
  // Pad the values with leading zeros
  const r = _hexPad(rgba.r.toString(16));
  const g = _hexPad(rgba.g.toString(16));
  const b = _hexPad(rgba.b.toString(16));
  let hexStr = `#${r}${g}${b}`;
  if ('a' in rgba) {
    const a = _hexPad(Math.round(rgba.a * 255).toString(16));
    hexStr = `${hexStr}${a}`;
  }
  // Return the hex string
  return hexStr;
};

/**
 * Verifies if a string is a color hexstring that has transparency below a threshold.
 *
 * @param {string} hexStr the string to be validated
 * @param {int} threshold the threshold agains the alpha value (if exists) if verified against
 *
 * @return {boolean} true if alpha <= threshold else false
 */
export const isHexWithTransparency = (hexStr, threshold = 100) => {
  if (!hexStr) {
    return false;
  }
  const hexNoHash = hexStr.replace('#', '');
  if (hexNoHash.length === 8) {
    if (parseInt(hexNoHash.substring(6, 8), 16) <= threshold) {
      return true;
    }
  } else if (hexNoHash === 'transparent') {
    return true;
  }
  return false;
};

/**
 * display a loading spinner or content
 * @param content
 * @param isLoading
 * @param {string | number} placeholder number or string to display if content is null
 * @returns {JSX.Element|string}
 */

export const renderLoadingOrContent = (content, isLoading, placeholder = 'N/A') => {
  if (isLoading) {
    return <LoadingSpinner fast />;
  }
  return content ?? placeholder;
};

/**
 * wrap content with a tooltip
 * @param target JSX.Element
 * @param content string
 * @param placement string
 * @returns {JSX.Element}
 */
export const wrapWithTooltip = ({ target, content, placement = 'auto' }) => {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{content}</Tooltip>}>
      {target}
    </OverlayTrigger>
  );
};

// same for popover
export const wrapWithPopover = ({ target, content, title = undefined, placement = 'auto' }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover>
          <Popover.Title>{title}</Popover.Title>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }
    >
      {target}
    </OverlayTrigger>
  );
};

/**
 * wrap an icon with a tooltip
 * @param iconProp
 * @param content
 * @param placement
 * @returns {JSX.Element}
 */
export const getIconWithTooltip = ({ iconProp, content, placement = 'auto' }) => {
  return wrapWithTooltip({
    target: <FontAwesomeIcon className="tooltip-icon" icon={iconProp} />,
    content,
    placement,
  });
};

/**
 * wrap an icon with a popover
 * @param iconProp
 * @param popoverContent
 * @param placement
 * @returns {JSX.Element}
 */
export const getIconWithPopover = ({
  iconProp,
  content,
  title = undefined,
  placement = 'auto',
}) => {
  return wrapWithPopover({
    target: <FontAwesomeIcon className="tooltip-icon" icon={iconProp} />,
    content,
    title,
    placement,
  });
};

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

/**
 * Split filename into name and extension
 * @param filename
 * @returns {[*,*]|[undefined,string]|[string,string]}
 */
export const splitExt = (filename) => {
  if (!filename) return ['', ''];
  const matches = filename.match(/(.*)\.([a-z0-9_]{2,4})$/i);
  if (matches?.length === 3) {
    return [matches[1], matches[2]];
  }
  return [filename, ''];
};
